import React, { useState } from "react";
import * as S from "./style";
import PageTitle from "components/PageTitle";
import bgTitleImg from "assets/images/4.jpg";
import { useHistory } from "react-router-dom";

function Policy() {
  const history = useHistory();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const gotoSellForm = () => {
    if (isChecked) {
      history.push("/sell-form");
    }
  };

  return (
    <React.Fragment>
      <PageTitle backgroundImage={bgTitleImg} title="이용약관/개인정보 취급방침"/>
      <S.Wrapper>
        <div className="container policy">
          <div className="row">
            <div className="sub-tit">
              <h3 className="title-block m-t-0 bold">
                [필수] 개인정보 취급방침 동의
              </h3>
            </div>
            <div className="box-1 bg-f6">
              <h4>제1장 총칙</h4>
              <p className="bold">제1조 목적</p>
              <p>
                이 약관은 sambytech 및 sambytech관련 제반 서비스 (이하:
                서비스)의 이용조건 및 절차에 관한 회사와 회원간의 권리 의무 및
                책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
                <br />이 약관은 sambytech 및 sambytech관련 제반 서비스 (이하:
                서비스)의 이용조건 및 절차에 관한 회사와 회원간의 권리 의무 및
                책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.
              </p>
              <p className="bold">제2조 (약관의 명시, 설명과 개정)</p>
              <p>
                1. 이 약관의 내용은 회사의 서비스 회원가입 관련 사이트에
                게시하거나 기타의 방법으로 사용자에게 공지 하고, 이용자가
                회원으로 가입하면서 이 약관에 동의함으로써 효력을
                발생합니다.입하면서 이 약관에 동의함으로써 효력이 발생합니다.
                <br />
                2. 회사는 "약관의 규제에 관한 법률", "정보통신망이용촉진 및
                정보보호 등에 관한 법률", 등 관련법을 위배하지 않는 범위에서 이
                약관을 개정할 수 있습니다.
                <br />
                3. 회사가 약관을 개정할 경우에는 적용일자 및 개정 사유를
                명시하여 현행약관과 함께 회사 사이트의 초기화면이나 팝업화면
                또는 공지사항란에 그 적용일자 7일 이전부터 적용일자 전일까지
                공지합니다.
              </p>
            </div>
            <div className="sub-tit">
              <h3 className="title-block bold">
                [필수] 개인정보 취급방침 동의
              </h3>
            </div>
            <div className="box-1 bg-f6">
              <h4>개인정보취급방침 안내</h4>
              <p>
                sambytech은 가상화폐 회원 개개인의 개인정보보호를 매우
                중요시하여,『정보통신망 이용 촉진 등에 관한 법률』상의
                개인정보보호 규정 및 정보통신부가 제정한 『개인정보보호지침』을
                준수하고자 개인정보보호를 위한 방침을 만들어 실천하고 있습니다.
                <br />
                이러한 방침은 관련 법률 및 정부지침의 변경과 sambytech의 내부
                방침의 변경에 의하여 수시로 변경될 수 있으며 개인정보취급방침이
                변경될 경우 변경사항은 베스트 코인의 홈페이지에 게시됩니다.
                <br />
                <br />또 변경사항에 의문이 있으실 경우는 언제라도 아래의 주소로
                담당자에게 문의하시거나 sambytech의 홈페이지를 방문하여 확인하실
                수 있습니다.
                <br />
                <br />
                sambytech은 개인정보취급방침을 통하여 회원이 제공하는 개인정보가
                어떠한 용도와 방식으로 이용되고 있으며 개인정보보호를 위해
                어떠한 조치가 취해지고 있는지 언제나 용이하게 보실 수 있도록
                조치하고 있습니다.
                <br />
                <br />
                개인정보취급방침은 다음과 같은 내용을 담고 있습니다.
              </p>
            </div>
          </div>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default Policy;
