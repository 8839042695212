import React, {
  useState,
  FormEvent,
  ChangeEvent,
} from "react";
import { useHistory } from 'react-router-dom';
import * as S from "./style";
import PageTitle from "components/PageTitle";
import bgTitleImg from "assets/images/4.jpg";
import api from "api";
// import { useHistory } from "react-router-dom";

interface FormValuesType {
  fullName: string;
  passwd: string;
  contact: string;
  email: string;
  title: string;
  content: string;
}

interface PayloadType {
  name: string;
  password: string;
  contact: string;
  email: string;
  title: string;
  content: string;
}

function Inquire() {
  const history = useHistory();
  const [formValues, setFormValues] = useState<FormValuesType>({
    fullName: "",
    passwd: "",
    contact: "",
    email: "",
    title: "",
    content: "",
  });

  const [errors, setErrors] = useState<Partial<FormValuesType>>({});
  const [isChecked, setIsChecked] = useState<boolean>(false);

  const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    if (
      formValues.fullName &&
      formValues.passwd &&
      formValues.contact &&
      formValues.email &&
      formValues.title &&
      formValues.content &&
      isChecked
    ) {
      // Gửi dữ liệu form đi
      console.log("form data:", formValues);

      const payload: PayloadType = {
        name: formValues.fullName,
        password: formValues.passwd,
        contact: formValues.contact,
        email: formValues.email,
        title: formValues.title,
        content: formValues.content,
      };

      try {
        const response = await api.post(
          "/api/v1/sell-coin/create-inquire",
          payload
        );
        if (response) {
          alert("접수되었습니다.");
          setFormValues({
            fullName: "",
            passwd: "",
            contact: "",
            email: "",
            title: "",
            content: "",
          });
        }
      } catch (error) {
        alert("오류.");
        console.log(error);
      }
      // Reset lỗi
      setErrors({});
    } else {
      console.log("please input information.");
      console.log("form data:", formValues);
      // Thiết lập lỗi cho các trường không được nhập
      const fieldErrors: Partial<FormValuesType> = {};
      for (const field in formValues) {
        if (!formValues[field as keyof FormValuesType]) {
          fieldErrors[field as keyof FormValuesType] = "require";
        } else {
          fieldErrors[field as keyof FormValuesType] = "";
        }
      }
      setErrors(fieldErrors);
    }
  };

  const handleChange = (
    e: ChangeEvent<HTMLSelectElement | HTMLInputElement | HTMLTextAreaElement>
  ) => {
    const { name, value } = e.target;

    if (name === "inputMoney" && Number(value) <= 0) {
      return;
    }

    setFormValues({
      ...formValues,
      [name]: value,
    });

    setErrors((prevErrors) => {
      const updatedErrors = { ...prevErrors };
      if (value) {
        delete updatedErrors[name as keyof FormValuesType];
      } else {
        updatedErrors[name as keyof FormValuesType] = "require";
      }
      return updatedErrors;
    });
  };

  const goToPolicy = (event: any) => {
    event.preventDefault();
    const path = event.target.getAttribute('href');
    const url = window.location.origin + path;
    window.open(url, '_blank');
  }

  return (
    <React.Fragment>
      <PageTitle backgroundImage={bgTitleImg} title="고객지원"/>
      <S.Wrapper>
        <div className="container sub-page inquire">
          <div className="row">
            <div className="col-lg-12">
              <form onSubmit={handleSubmit}>
                <input type="hidden" name="token" defaultValue="" />
                <div className={`form-group row ${errors.fullName}`}>
                  <label
                    htmlFor="example-text-input"
                    className="col-3 col-form-label"
                  >
                    이름<em>*</em>
                  </label>
                  <div className="col-9">
                    <input
                      className="form-control"
                      name="fullName"
                      type="text"
                      id="example-text-input"
                      value={formValues.fullName}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={`form-group row ${errors.passwd}`}>
                  <label
                    htmlFor="example-password-input"
                    className="col-3 col-form-label"
                  >
                    비밀번호<em>*</em>
                  </label>
                  <div className="col-9">
                    <input
                      className="form-control"
                      name="passwd"
                      type="password"
                      id="example-password-input"
                      value={formValues.passwd}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={`form-group row ${errors.contact}`}>
                  <label
                    htmlFor="example-tel-input"
                    className="col-3 col-form-label"
                  >
                    연락처<em>*</em>
                  </label>
                  <div className="col-9">
                    <input
                      className="form-control"
                      name="contact"
                      type="tel"
                      id="example-tel-input"
                      value={formValues.contact}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={`form-group row ${errors.email}`}>
                  <label
                    htmlFor="example-email-input"
                    className="col-3 col-form-label"
                  >
                    이메일<em>*</em>
                  </label>
                  <div className="col-9">
                    <input
                      className="form-control"
                      name="email"
                      type="email"
                      id="example-email-input"
                      value={formValues.email}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={`form-group row ${errors.title}`}>
                  <label
                    htmlFor="example-search-input"
                    className="col-3 col-form-label"
                  >
                    제목<em>*</em>
                  </label>
                  <div className="col-9">
                    <input
                      type="text"
                      name="title"
                      // required
                      className="form-control required"
                      value={formValues.title}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={`form-group row ${errors.content}`}>
                  <label
                    htmlFor="example-url-input"
                    className="col-3 col-form-label"
                  >
                    내용<em>*</em>
                  </label>
                  <div className="col-9">
                    <textarea
                      name="content"
                      // required
                      rows={5}
                      className="form-control required"
                      value={formValues.content}
                      onChange={handleChange}
                    />
                  </div>
                </div>
                <div className={`che-inq mb-1 mt-4`}>
                  <div className="custom-control custom-checkbox">
                    <input
                      type="checkbox"
                      name="reminders"
                      id="reminders"
                      className="custom-control-input"
                      checked={isChecked}
                      onChange={() => setIsChecked(!isChecked)}
                    />
                    <label className="custom-control-label" htmlFor="reminders">
                      개인정보수집 이용 동의
                    </label>
                    <span className="bottom-agree">
                      <a onClick={goToPolicy} href="/policy">
                        [자세히보기]
                      </a>
                    </span>
                  </div>
                  <div className="t-a-c">
                    <button
                      type="submit"
                      className="btn btn-danger"
                      disabled={!isChecked}
                    >
                      완료
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default Inquire;
