import * as S from "./style";
import PageTitle from "components/PageTitle";
import bgTitleImg from "assets/images/4.jpg";
import React from "react";
import { useHistory, useLocation } from "react-router-dom";
import { Button } from "antd";

function BankInfo() {
  const { state }: any = useLocation();
  const history = useHistory();

  return (
    <React.Fragment>
      <PageTitle backgroundImage={bgTitleImg} title="구매대행계좌" />
      <S.Wrapper>
        <div className="container sub-page inquire">
          <div className="heading-text heading-section">
            <h2>구매대행계좌</h2>
          </div>
          <S.Content>
            <S.Detail>
              <S.Label>계좌은행:</S.Label>
              <S.Info>{state?.bank_name}</S.Info>
            </S.Detail>
            <S.Detail>
              <S.Label>계좌번호:</S.Label>
              <S.Info>{state?.account_number}</S.Info>
            </S.Detail>
            <S.Detail>
              <S.Label>계좌주명:</S.Label>
              <S.Info>{state?.account_name}</S.Info>
            </S.Detail>
            <S.Action>
              <Button
                className="btn btn-v02 btn-rounded"
                size="large"
                onClick={() => history.push("/mypage")}
              >
                마이페이지 이동
              </Button>
            </S.Action>
          </S.Content>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default BankInfo;
