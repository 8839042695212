import styled from "styled-components";

export const Wrapper = styled.div`
  .slide .slide-captions {
    > h1 {
      font-size: 70px;
      font-weight: 700;
      line-height: 80px;
      margin-bottom: 30px;
      @media (max-width: 767px) {
        font-size: 40px;
        line-height: 50px;
      }
    }
    > p {
      font-size: 20px;
      font-weight: 400;
      margin-bottom: 40px;
      @media (max-width: 1024px) {
        font-size: 16px;
        font-weight: 400;
        line-height: 24px;
        margin-bottom: 16px;
      }
    }
  }
  .slick-dots {
    bottom: 25px;
    li {
      width: 30px;
      height: 4px;
      margin: 0 4px;
      button {
        width: 30px;
        height: 4px;
        padding: 0;
        &:before {
          font-size: 0;
          width: 30px;
          height: 4px;
          background-color: #ffffff;
        }
      }
    }
  }
`;
export const SlideInner = styled.div<{ data: string }>`
  height: 100vh;
  max-height: 100%;
  min-height: 620px;
  background-image: url(${({ data }) => data});
  .container {
    height: 100%;
    display: flex;
    align-items: center;
  }
`;
