import styled from "styled-components";

export const Wrapper = styled.section`
  .modal-title {
    text-align: center;
  }
`;

export const Content = styled.div`
  max-width: 1000px;
  margin-right: auto;
`;

export const ModalContent = styled.div`
  padding: 0 4px;
  p {
    margin: 0;
    line-height: 35px;
  }
`;

export const ModalTitle = styled.p`
  text-align: center;
  padding-top: 20px;
`;
