import styled from "styled-components";

export const Wrapper = styled.section``;

export const Content = styled.div`
  max-width: 1000px;
  margin-right: auto;
`;

export const Detail = styled.div`
  display: flex;
  margin-bottom: 24px;
`;

export const Label = styled.div`
  width: 200px;
  height: 50px;
  padding: 0 16px;
  margin-right: 20px;
  border-radius: 6px;
  background: rgb(246, 246, 246);
  display: inline-flex;
  align-items: center;
  font-weight: 400;
`;

export const Info = styled.div`
  display: inline-flex;
  align-items: center;
  height: 50px;
  border-radius: 6px;
  background: rgb(246, 246, 246, 0.7);
  width: 100%;
  padding: 0 16px;
`;

export const Action = styled.div`
  display: flex;
  justify-content: flex-end;
`;
