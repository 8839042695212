import { apiConfig } from "api";
import { ApiUrl } from "constant/endpoint";

interface LoginPayload {
  uid: string;
  password: string;
}

export const loginApi = (payload: LoginPayload) => {
  return apiConfig.request({
    url: ApiUrl.LOGIN,
    method: "POST",
    data: payload,
  });
};
