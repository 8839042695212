import { createSlice } from "@reduxjs/toolkit";
import { RootState } from "app/store";

export interface UserState {
  loading: boolean;
  currentUser: any;
  errorCode: any;
  dataUser: any;
}

const initialState: UserState = {
  loading: false,
  currentUser: null,
  errorCode: null,
  dataUser: null,
};

export const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    userLogin: (state) => {
      state.loading = true;
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.currentUser = action.payload;
      state.errorCode = null;
    },
    loginFailed: (state, action) => {
      state.loading = false;
      state.errorCode = action.payload;
      state.currentUser = null;
    },
    logout: (state) => {
      state.loading = false;
      state.errorCode = null;
      state.currentUser = null;
    },
    setUserData: (state, action) => {
      state.dataUser = action.payload;
    },
  },
});

export const { setUserData, userLogin, loginSuccess, loginFailed, logout } =
  userSlice.actions;

export const loginSelect = (state: RootState) => state.user;
export const tokenSelect = (state: RootState) =>
  state.user.currentUser?.accessToken;
export const userDataSelect = (state: RootState) => state.user.dataUser;

export default userSlice.reducer;
