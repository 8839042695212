import * as S from "./style";
// import { Link, useLoaderData } from "react-router-dom";

interface Props {
  backgroundImage: string;
  title: string;
}

function PageTitle({ backgroundImage, title }: Props) {
  return (
    <S.Wrapper id="page-title" className="text-light" data={backgroundImage}>
      <div className="container">
        <div className="page-title">
          <h1>{title}</h1>
        </div>
      </div>
    </S.Wrapper>
  );
}

export default PageTitle;
