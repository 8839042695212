import * as S from "./style";
import PageTitle from "components/PageTitle";
import bgTitleImg from "assets/images/4.jpg";
import React, { useEffect, useMemo } from "react";
import { Button, Form, Input, message, Space } from "antd";
import { sendCodeVerifyApi, verifyPhoneNumberApi } from "api/register";
import { Link, useHistory, useLocation } from "react-router-dom";
import { SITE_NAME } from "constant/configs";
import { useCountTime } from "customHook/useCountTime";
import { secondToMinute } from "utils/format";
const TIME_TO_SENDEMAIL = 180;
const OTP_SEND_KEY = "otp_send";

function VerifyPhoneNumber() {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const history = useHistory();
  const { countTime, handleSetCountTime } = useCountTime(0, OTP_SEND_KEY);
  const { state }: any = useLocation();
  // console.log("state", state);

  useEffect(() => {
    // localStorage.setItem(OTP_SEND_KEY, JSON.stringify(null));
    handleSetCountTime(0);
  }, []);

  const isReadySend = useMemo(() => {
    return countTime <= 0;
  }, [countTime > 0]);

  const handleVerify = async (values: any) => {
    try {
      const resp: any = await verifyPhoneNumberApi({
        id: state,
        phone: values.phone,
        code: values.code,
      });
      // console.log("handleRegister resp", resp);
      if (resp?.result) {
        messageApi.open({
          type: "success",
          content: resp?.message || "성공했습니다.",
          duration: 1,
          onClose: () => {
            history.push("/login");
          },
        });
        return;
      }
      messageApi.open({
        type: "error",
        content: resp?.error?.message || "오류입니다",
      });
    } catch (error) {
      console.log("handleRegister error", error);
    }
  };

  const handleSendVerify = async () => {
    // if (isReadySend) {
    //   handleSetCountTime(TIME_TO_SENDEMAIL);
    // }
    try {
      const resp: any = await sendCodeVerifyApi({
        phone: form.getFieldValue("phone"),
      });
      // console.log("handleSendVerify", resp);
      if (resp?.result) {
        handleSetCountTime(TIME_TO_SENDEMAIL);
        messageApi.open({
          type: "success",
          content: resp?.message,
        });
        return;
      }
      messageApi.open({
        type: "error",
        content: resp?.error?.message,
      });
    } catch (error) {
      console.log("sendCodeVerifyApi error", error);
    }
  };

  const checkPhoneNumber = () => {
    form
      .validateFields(["phone"])
      .then((values) => {
        // If validation is successful, you can proceed with your logic here
        // console.log("Validated values:", values);
        handleSendVerify();
      })
      .catch((info) => {
        // If validation fails, you can handle it here
        console.log("Validation Failed:", info);
      });
  };

  console.log("isReadySend", isReadySend);

  return (
    <React.Fragment>
      {contextHolder}
      <PageTitle backgroundImage={bgTitleImg} title="회원가입" />
      <S.Wrapper>
        <div className="container sub-page inquire">
          <div className="heading-text heading-section">
            <h2>회원가입</h2>
          </div>
          <S.Content>
            <div className="custom-form-antd">
              <Form form={form} name="control-hooks" onFinish={handleVerify}>
                <Form.Item
                  name="phone"
                  label="전화번호"
                  rules={[
                    { required: true, message: "정보를 입력해주세요." },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject();
                        }
                        if (isNaN(value)) {
                          return Promise.reject("숫자를 입력해주세요.");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Space.Compact style={{ width: "100%" }}>
                    <Input style={{ height: 50 }} />
                    <Button
                      style={{
                        height: 50,
                        borderTopLeftRadius: 0,
                        borderBottomLeftRadius: 0,
                      }}
                      className="btn btn-v01"
                      type="primary"
                      onClick={checkPhoneNumber}
                      // disabled={!isReadySend}
                    >
                      {isReadySend ? "전송" : "재전송"}
                    </Button>
                  </Space.Compact>
                </Form.Item>
                <Form.Item
                  name="code"
                  label="인증번호"
                  rules={[{ required: true, message: "정보를 입력해주세요." }]}
                >
                  <Input
                    style={{ height: 50 }}
                    maxLength={30}
                    suffix={
                      !isReadySend && (
                        <div className="count-down-mess">
                          <p>
                            {/* 인증번호 만료시간 */}
                            <span>{secondToMinute(countTime)}</span>
                          </p>
                        </div>
                      )
                    }
                  />
                </Form.Item>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    htmlType="submit"
                    size="large"
                    className="btn btn-v01 btn-rounded"
                    style={{ marginLeft: "200px" }}
                    // onClick={() => history.push("/login")}
                  >
                    인증
                  </Button>
                </div>
              </Form>
            </div>
            <p style={{ marginTop: 20, fontSize: 16 }}>
              {SITE_NAME}의{" "}
              <Link to="/terms-of-use" style={{ fontWeight: "bold" }}>
                이용약관
              </Link>
              ,
              <Link to="/privacy-policy" style={{ fontWeight: "bold" }}>
                개인정보 처리방침
              </Link>
              을 확인하시고 내용에 동의하실 경우에만 <br />
              회원가입을 진행해주십시오.
            </p>
          </S.Content>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default VerifyPhoneNumber;
