import { apiConfig } from "api";
import { ApiUrl } from "constant/endpoint";

interface RegisterPayload {
  uid: string;
  name: string;
  password: string;
  bank_name: string;
  account_number: string;
  account_name: string;
  // phone: string
}

interface SendCodeVerifyPayload {
  phone: string;
}

interface VerifyPhoneNumberPayload {
  id: number;
  phone: string;
  code: string;
}

export const register = (payload: RegisterPayload) => {
  return apiConfig.request({
    url: ApiUrl.REGISTER,
    method: "POST",
    data: payload,
  });
};

export const sendCodeVerifyApi = (payload: SendCodeVerifyPayload) => {
  return apiConfig.request({
    url: ApiUrl.SEND_VERIFY_PHONE_NUMBER,
    method: "POST",
    data: payload,
  });
};

export const verifyPhoneNumberApi = (payload: VerifyPhoneNumberPayload) => {
  return apiConfig.request({
    url: ApiUrl.VERIFY_PHONE_NUMBER,
    method: "POST",
    data: payload,
  });
};
