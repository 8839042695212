import ApproveWarningModal from "./ApproveWarningModal";
import LockBuySellModal from "./LockBuySellModal";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "app/store";
import {
  closeApproveWarning,
  closeLockBuySell,
} from "features/popupManage/popupManageSlice";
import { useHistory } from "react-router-dom";

const ModalList = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const popupManage = useSelector((state: RootState) => state.popupManage);

  return (
    <div className="">
      <ApproveWarningModal
        open={popupManage.isOpenApproveWarning}
        onCancel={() => {
          dispatch(closeApproveWarning());
          history.push("/");
        }}
        handleOk={() => {
          dispatch(closeApproveWarning());
          history.push("/");
        }}
      />
      <LockBuySellModal
        open={popupManage.isOpenLockBuySell}
        onCancel={() => {
          dispatch(closeLockBuySell());
          history.push("/");
        }}
        handleOk={() => {
          dispatch(closeLockBuySell());
          history.push("/");
        }}
      />
    </div>
  );
};

export default ModalList;
