import * as S from "./style";
import PageTitle from "components/PageTitle";
import bgTitleImg from "assets/images/4.jpg";
import React from "react";
import { Button, Form, Input, message } from "antd";
import { register } from "api/register";
import { Link, useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { openLoading, closeLoading } from "features/loading/loadingSlice";
import { SITE_NAME } from "constant/configs";

function Register() {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const history = useHistory();
  const dispatch = useDispatch();

  const handleRegister = async (values: any) => {
    dispatch(openLoading());
    try {
      const resp: any = await register({
        uid: values.uid,
        name: values.name,
        password: values.password,
        bank_name: values.bank_name,
        account_name: values.account_name,
        account_number: values.account_number,
        // phone: values.phone,
      });
      // console.log("handleRegister resp", resp);
      if (resp?.result) {
        // messageApi.open({
        //   type: "success",
        //   content: resp?.message || "성공했습니다.",
        //   duration: 1,
        //   onClose: () => {
        //     dispatch(closeLoading());
        //     history.push({
        //       pathname: "/verify-phone-number",
        //       state: resp?.data?.id,
        //     });
        //   },
        // });
        history.push({
          pathname: "/verify-phone-number",
          state: resp?.data?.id,
        });
      }
      messageApi.open({
        type: "error",
        content: resp?.error?.message || "오류입니다",
      });
      dispatch(closeLoading());
    } catch (error) {
      console.log("handleRegister error", error);
      dispatch(closeLoading());
    }
  };

  return (
    <React.Fragment>
      {contextHolder}
      <PageTitle backgroundImage={bgTitleImg} title="회원가입" />
      <S.Wrapper>
        <div className="container sub-page inquire">
          <div className="heading-text heading-section">
            <h2>회원가입</h2>
          </div>
          <S.Content>
            <div className="custom-form-antd">
              <Form form={form} name="control-hooks" onFinish={handleRegister}>
                <Form.Item
                  name="uid"
                  label="아이디"
                  rules={[{ required: true, message: "정보를 입력해주세요." }]}
                >
                  <Input style={{ height: 50 }} />
                </Form.Item>
                <Form.Item
                  name="name"
                  label="이름"
                  rules={[{ required: true, message: "정보를 입력해주세요." }]}
                >
                  <Input style={{ height: 50 }} />
                </Form.Item>
                {/* <Form.Item
                  name="phone"
                  label="전화번호"
                  rules={[
                    { required: true, message: "정보를 입력해주세요." },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject();
                        }
                        if (isNaN(value)) {
                          return Promise.reject("숫자를 입력해주세요.");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input style={{ height: 50 }} />
                </Form.Item> */}
                <Form.Item
                  name="password"
                  label="비밀번호"
                  rules={[{ required: true, message: "정보를 입력해주세요." }]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="confirmPassword"
                  label="비밀번호 재확인"
                  dependencies={["password"]}
                  hasFeedback
                  rules={[
                    {
                      required: true,
                      message: "정보를 입력해주세요.",
                    },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("password") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("비밀번호가 일치하지 않습니다.")
                        );
                      },
                    }),
                  ]}
                >
                  <Input.Password />
                </Form.Item>
                <Form.Item
                  name="bank_name"
                  label="계좌은행"
                  rules={[{ required: true, message: "정보를 입력해주세요." }]}
                >
                  <Input style={{ height: 50 }} />
                </Form.Item>
                <Form.Item
                  name="account_number"
                  label="계좌번호"
                  rules={[
                    { required: true, message: "정보를 입력해주세요." },
                    () => ({
                      validator(_, value) {
                        if (!value) {
                          return Promise.reject();
                        }
                        if (isNaN(value)) {
                          return Promise.reject("숫자를 입력해주세요.");
                        }
                        return Promise.resolve();
                      },
                    }),
                  ]}
                >
                  <Input style={{ height: 50 }} />
                </Form.Item>
                <Form.Item
                  name="account_name"
                  label="계좌주명"
                  rules={[
                    { required: true, message: "정보를 입력해주세요." },
                    ({ getFieldValue }) => ({
                      validator(_, value) {
                        if (!value || getFieldValue("name") === value) {
                          return Promise.resolve();
                        }
                        return Promise.reject(
                          new Error("이름과 계좌주명이 일치해야 합니다.")
                        );
                      },
                    }),
                  ]}
                >
                  <Input style={{ height: 50 }} />
                </Form.Item>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    htmlType="submit"
                    size="large"
                    className="btn btn-v01 btn-rounded"
                    style={{ marginLeft: "200px" }}
                  >
                    다음
                  </Button>
                </div>
              </Form>
            </div>
            <p style={{ marginTop: 20, fontSize: 16 }}>
              {SITE_NAME}의{" "}
              <Link to="/terms-of-use" style={{ fontWeight: "bold" }}>
                이용약관
              </Link>
              ,
              <Link to="/privacy-policy" style={{ fontWeight: "bold" }}>
                개인정보 처리방침
              </Link>
              을 확인하시고 내용에 동의하실 경우에만 <br />
              회원가입을 진행해주십시오.
            </p>
          </S.Content>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default Register;
