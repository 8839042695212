import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
  #root {
    overflow: hidden;
  }
  .require {
    .form-control {
      border-color: #CE041C;
    }
  }
  .custom-form-antd {
    .ant-form-item .ant-form-item-label >label {
      width: 200px;
      height: 50px;
      padding: 0 16px;
      margin-right: 20px;
      border-radius: 6px;
    }
  }
  .ant-input-number-input{
    min-height: 48px !important;
  }
  .ant-input-number-disabled .ant-input-number-input, .ant-input[disabled]{
    color: #000000;
  }
  @media (min-width: 1500px) {
  .container-bs{
    max-width: 1400px;
  }
  }
  .table-text{
    font-size: 14px;
    margin: 0;
  }
  .table-header-text{
    font-size: 14px;
    text-align: center;
    margin: 0;
  }
  .text-center{
    text-align: center;
  }
  .ant-pagination-item-link{
    line-height: 100%;
  }
  .usdt-line{
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-bottom: 10px;
    gap: 10px;
    font-weight: bold;
  }
  .count-down-mess{
    text-align: right;
    p,span{
      color: red;
      font-size: 14px;
      margin: 0;
    }
  }
`;

export default GlobalStyles;
