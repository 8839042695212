import { Table } from "antd";
import type { TablePaginationConfig, TableProps } from "antd";
import { listSellApi } from "api/sell";
import dayjs from "dayjs";
import { TableParams } from "pages/types";
import { useEffect, useState } from "react";
import { MoneyConvert } from "utils/format";

interface DataType {
  id: number;
  amount: string;
  total_exchange: string;
  user_address: string;
  bank_name: string;
  account_number: string;
  account_name: string;
  create_time: string;
}

const columns: TableProps<DataType>["columns"] = [
  {
    title: "생성시간",
    key: "create_time",
    dataIndex: "create_time",
    render: (_, record) => (
      <p className="table-text">
        {record.create_time.replace("T", " ").replace(".000Z", "")}
      </p>
    ),
    width: 200,
  },
  {
    title: "판매금액(원)",
    dataIndex: "total_exchange",
    key: "total_exchange",
    render: (_, record) => (
      <p className="table-text">
        {MoneyConvert(Number(record.total_exchange || 0), true)}
      </p>
    ),
    width: 200,
  },
  {
    title: "판매수량(oUSDT)",
    dataIndex: "amount",
    key: "amount",
    render: (_, record) => (
      <p className="table-text">
        {MoneyConvert(Number(record.amount || 0), true)}
      </p>
    ),
    width: 200,
  },
  {
    title: "보내는 지갑주소",
    dataIndex: "user_address",
    key: "user_address",
    render: (_, record) => <p className="table-text">{record.user_address}</p>,
  },
  {
    title: "계좌은행",
    key: "bank_name",
    dataIndex: "bank_name",
    render: (_, record) => <p className="table-text">{record.bank_name}</p>,
  },
  {
    title: "계좌번호",
    key: "account_number",
    dataIndex: "account_number",
    render: (_, record) => (
      <p className="table-text">{record.account_number}</p>
    ),
  },
  {
    title: "계좌주명",
    key: "account_name",
    dataIndex: "account_name",
    render: (_, record) => <p className="table-text">{record.account_name}</p>,
  },
];

const DataSell = () => {
  const [data, setData] = useState<DataType[]>([]);
  const [tableParams, setTableParams] = useState<TableParams>({
    pagination: {
      current: 1,
      pageSize: 5,
    },
  });
  const [totalElement, setTotalElement] = useState<number>(0);

  const getDataSell = async () => {
    try {
      const resp: any = await listSellApi({
        page: tableParams?.pagination?.current || 1,
        perPage: tableParams?.pagination?.pageSize || 5,
        query: "",
        startTime: "",
        endTime: "",
      });
      // console.log("getDataBuy resp", resp);
      if (resp?.result) {
        const dataResp = resp?.data?.items?.map((item: any, index: number) => {
          return {
            ...item,
            id:
              index +
              1 +
              ((tableParams?.pagination?.current || 1) - 1) *
                (tableParams?.pagination?.pageSize || 5),
          };
        });
        setData(dataResp || []);
        setTableParams({
          ...tableParams,
          pagination: {
            ...tableParams.pagination,
            // total: resp?.data?.pageable?.totalElement,
          },
        });
        setTotalElement(resp?.data?.pageable?.totalElement);
      }
    } catch (error) {
      console.log("getDataBuy error", error);
      setTableParams({
        pagination: {
          current: 1,
          pageSize: 5,
        },
      });
    }
  };

  useEffect(() => {
    getDataSell();
  }, []);

  const handleTableChange = (pagination: TablePaginationConfig) => {
    console.log("handleTableChange", pagination?.current);

    setTableParams({
      pagination: pagination,
    });
    if (pagination.pageSize !== tableParams.pagination?.pageSize) {
      setData([]);
    }
  };

  return (
    <div style={{ overflowX: "auto" }}>
      <div style={{ width: 1370 }}>
        <Table
          rowKey="id"
          columns={columns}
          dataSource={data}
          pagination={{
            position: ["bottomCenter"],
            pageSize: tableParams?.pagination?.pageSize,
            current: tableParams?.pagination?.current,
            total: totalElement,
            showSizeChanger: false,
          }}
          onChange={handleTableChange}
        />
      </div>
    </div>
  );
};

export default DataSell;
