import React, { useEffect, useState } from "react";
import * as S from "./style";
import PageTitle from "components/PageTitle";
import bgTitleImg from "assets/images/4.jpg";
import { useHistory } from "react-router-dom";
import { userInfoApi } from "api/user";
import {
  openApproveWarning,
  openLockBuySell,
} from "features/popupManage/popupManageSlice";
import { useDispatch } from "react-redux";

function Purchase() {
  const dispatch = useDispatch();
  const history = useHistory();
  const [isChecked, setIsChecked] = useState<boolean>(false);

  useEffect(() => {
    getUserInfo();
  }, []);

  const getUserInfo = async () => {
    try {
      const resp: any = await userInfoApi();
      if (resp?.result) {
        if (resp?.data?.approve === 0) {
          dispatch(openApproveWarning());
        } else {
          if (resp?.data?.lock_buy_coin === 1) {
            dispatch(openLockBuySell());
          }
        }
      }
    } catch (error) {
      console.log("User Info error", error);
    }
  };

  const gotoPurchaseForm = () => {
    if (isChecked) {
      history.push("/purchase-form");
    }
  };

  return (
    <React.Fragment>
      <PageTitle backgroundImage={bgTitleImg} title="간편구매" />
      <S.Wrapper>
        <div className="container sub-page">
          <div className="row">
            <div className="col-lg-12">
              <div className="heading-text heading-section">
                <h2>간편구매</h2>
              </div>
            </div>
            <div className="col-lg-12">
              <div className="sub-tit">
                <p className="lead">
                  다음의 유의사항을 읽으시고 확인, 이해, 동의 하신다면 진행
                  부탁드립니다.
                </p>
                {/*p class="lead fon-col-fe bank_info">&nbsp;</p*/}
                <p className="lead">해당신청 후 꼭 입금 바랍니다.</p>
              </div>
              <div className="agree_cont">
                <ul className="dot_list">
                  <li style={{ color: "red" }}>
                    사기 또는 보이스피싱이 의심되는 경우에 한해서 코인전송이
                    72시간 지연전송됨을 알려드립니다
                  </li>
                  <li>최근 가상화폐 사기가 급증하고 있습니다. 주의하세요!</li>
                  <li>
                    가상화폐 지갑 생성시 개인정보입력이 없어 지갑주인을 찾지
                    못합니다. 코인 신청이 완료 된 후에는 환불, 수정이
                    불가합니다. 확실한 거래만 진행부탁 드립니다.
                  </li>
                  <li>
                    가상화폐는 전송(블록체인 네트워크접수)후 되돌리거나 수정,
                    취소를 할 수 없습니다. # 모든 책임은 고객 본인에게 있습니다.
                    또한, 귀사는 책임지지 않습니다.
                  </li>
                  <li>
                    범죄우려 (불법 영상,마약 류,약물 등)가 있는 거래는 시도하지
                    마세요.
                  </li>
                  <li>
                    대행 결제금액은 계좌이체,CD이체,무통장입금 등의 현금결제만
                    가능합니다.(카드,Pay등 불가)
                  </li>
                  <li>
                    신청자명과 입금자명은 같아야 하며, 이체된 계좌의 명의인도
                    동일해야 합니다. (불일치시 거래 불가)
                  </li>
                  <li>
                    기타 부정거래가 의심되거나, 대행신청 금액이 클 경우
                    본인인증을 요구할 수 있습니다.
                  </li>
                  <li>
                    가상화폐(암호화화폐)의 전송(송금)과정은 블록체인네트워크에서
                    처리되므로 트랜잭션승인에 소요되는 시간은 저희가 개입하거나,
                    보장해드릴 수 없어 간혹 시간이 많이 소요되는 경우도 있습니다
                    양해 부탁드립니다.
                  </li>
                  <li>
                    최근 가상화폐(암호화화폐)를 보내라는 거래방식의 피싱 및
                    금융사기 시도가 연이어 발생하고 있으므로 주의가 필요합니다.
                    저희는 고객이 입력한 지갑주소의 어떠한 정보도 알 수 없으며
                    사기를 당했을 시 도움을 드릴 수 있는 방법이 없습니다.
                  </li>
                  <li>
                    저희는 해당 관련된 범죄에 대하여 강력히 규탄하며 사건발생시
                    수사기관과 적극 협조하여 최대한 그러한 일이 발생하지 않도록
                    노력하겠습니다.
                  </li>
                  <li>
                    이러한 위험성을 인지하고 가상화폐(암호화화폐)의
                    구매전송대행을 이용하시는 모든 책임은 당사자 본인에게 있음을
                    안내 드립니다. 또한, 귀사는 책임을 일절 지지 않습니다.
                  </li>
                </ul>
              </div>
              <div className="form-group mb-1 mt-2">
                <div className="custom-control custom-checkbox">
                  <input
                    type="checkbox"
                    name="reminders"
                    id="reminders"
                    className="custom-control-input"
                    checked={isChecked}
                    onChange={() => setIsChecked(!isChecked)}
                    required
                  />
                  <label className="custom-control-label" htmlFor="reminders">
                    상기 내용을 확인했습니다.
                  </label>
                </div>
                <div className="t-a-c">
                  <button
                    onClick={gotoPurchaseForm}
                    type="button"
                    className="btn btn-danger go_purchase"
                    disabled={!isChecked}
                  >
                    구매신청
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default Purchase;
