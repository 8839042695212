import { apiConfig } from "api";
import { ApiUrl } from "constant/endpoint";

export const bankInfoApi = () => {
  return apiConfig.request({
    url: ApiUrl.BANK_DEFAULT,
    method: "GET",
    tokenRequired: true,
  });
};

export const userInfoApi = () => {
  return apiConfig.request({
    url: ApiUrl.USER_INFO,
    method: "GET",
    tokenRequired: true,
  });
};

export const getAddressSellCoinApi = () => {
  return apiConfig.request({
    url: ApiUrl.GET_ADDRESS_SELL_COIN,
    method: "POST",
    tokenRequired: true,
  });
};

export const getRateKrwUsdtApi = () => {
  return apiConfig.request({
    url: ApiUrl.GET_RATE_KRW_USDT,
    method: "GET",
  });
};
