import * as S from "./style";
import PageTitle from "components/PageTitle";
import bgTitleImg from "assets/images/4.jpg";
import React from "react";
import { Button, Form, Input, message } from "antd";
import { loginApi } from "api/login";
import { useAppDispatch } from "app/hooks";
import { AppDispatch } from "app/store";
import { loginSuccess, userLogin } from "features/user/userSlice";
import { useHistory } from "react-router-dom";
import { openLoading, closeLoading } from "features/loading/loadingSlice";

function Login() {
  const [form] = Form.useForm();
  const [messageApi, contextHolder] = message.useMessage();
  const dispatch: AppDispatch = useAppDispatch();
  const history = useHistory();

  const handleLogin = async (values: any) => {
    dispatch(openLoading());
    try {
      dispatch(userLogin());
      const resp: any = await loginApi({
        uid: values.uid,
        password: values.password,
      });
      console.log("resp handleRegister", resp);
      if (resp?.result) {
        dispatch(loginSuccess(resp?.data));
        messageApi.open({
          type: "success",
          content: resp?.message || "성공했습니다.",
          duration: 2,
          onClose() {
            dispatch(closeLoading());
            history.push("/");
          },
        });
        return;
      }
      if (resp?.error?.message === "인증 완료되지 않았습니다") {
        messageApi.open({
          type: "error",
          content: resp?.error?.message || "오류입니다.",
          duration: 2,
          onClose() {
            dispatch(closeLoading());
            history.push({
              pathname: "/verify-phone-number",
              state: resp?.error?.data?.id,
            });
          },
        });
        return;
      }
      messageApi.open({
        type: "error",
        content: resp?.error?.message || "오류입니다.",
      });
      dispatch(closeLoading());
    } catch (error) {
      console.log("handleLogin error", error);
      dispatch(closeLoading());
    }
  };

  return (
    <React.Fragment>
      {contextHolder}
      <PageTitle backgroundImage={bgTitleImg} title="로그인" />
      <S.Wrapper>
        <div className="container sub-page inquire">
          <div className="heading-text heading-section">
            <h2>로그인</h2>
          </div>
          <S.Content>
            <div className="custom-form-antd">
              <Form form={form} name="control-hooks" onFinish={handleLogin}>
                <Form.Item
                  name="uid"
                  label="아이디"
                  rules={[{ required: true, message: "정보를 입력해주세요." }]}
                >
                  <Input style={{ height: 50 }} />
                </Form.Item>
                <Form.Item
                  name="password"
                  label="비밀번호"
                  rules={[{ required: true, message: "정보를 입력해주세요." }]}
                >
                  <Input.Password />
                </Form.Item>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <Button
                    size="large"
                    className="btn btn-v02 btn-rounded"
                    htmlType="submit"
                  >
                    로그인
                  </Button>
                </div>
              </Form>
            </div>
          </S.Content>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default Login;
