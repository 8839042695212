import SliderComp from "components/SliderComp";
import { animateScroll as scroll } from "react-scroll";
import img4 from "../../assets/images/4.jpg";
import imgBa1 from "../../assets/images/img-ba1.png";
import imgBa2 from "../../assets/images/img-ba2.png";
import img01 from "../../assets/images/img01.jpg";
import img02 from "../../assets/images/img02.jpg";
import img03 from "../../assets/images/img03.jpg";
import img05 from "../../assets/images/img05.jpg";
// import * as S from "./style";
import { useEffect } from "react";

function Homepage() {
  const menu = document.getElementById("menu1");

  useEffect(() => {
    if (window.location.hash === "#menu1") {
      const element = document.getElementById("menu1");
      if (element) {
        const position = element.offsetTop;
        scroll.scrollTo(position, {
          spy: true,
          smooth: true,
          offset: -50,
          duration: 500,
        });
      }
    } else if (window.location.hash === "#menu2") {
      const element = document.getElementById("menu2");
      if (element) {
        const position = element.offsetTop;
        scroll.scrollTo(position, {
          spy: true,
          smooth: true,
          offset: -50,
          duration: 500,
        });
      }
    }
  }, []);

  return (
    // <S.Wrapper>
    <>
      {/* Inspiro Slider */}
      <div className="slider-container">
        {/* <div className="bg-overlay overlay-slide" /> */}
        <SliderComp />
      </div>
      {/*end: Inspiro Slider */}
      {/* About us */}
      {/* <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12 m-b-60 m-t-30">
              <h2 className="tit-v01">
                마음편한 자동투자
                <br />
                {SITE_NAME} 전략투자
              </h2>
              <p className="lead">
                BA알고리즘을 기반으로 한<br />
                뉴런 빅데이터 분석과
                <br />
                Time to go 전략으로
                <br />
                <br />
                오직 당신의 자산을 위해
                <br />
                {SITE_NAME} 역량을 쏟아 부었습니다.
              </p>
              <a href="#services" className="btn btn-v01 btn-rounded">
                시작하기
              </a>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 t-a-c bg-f8">
              <p className="p-t-20">
                <img alt="img" src={img01} />
              </p>
            </div>
          </div>
        </div>
      </section> */}
      {/* end: About us */}
      {/* WHAT WE DO */}
      {/* <section className="background-grey">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 col-md-6 col-sm-12 t-a-c bg-f8">
              <p className="p-t-20">
                <img alt="img" src={img02} />
              </p>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12 m-b-60 m-t-30">
              <h2 className="tit-v02">
                가장 안전한 제테크
                <br />
                {SITE_NAME} 예치보상
              </h2>
              <p className="lead">
                디지털 자산 시장의 변동성은
                <br />
                하이리스크 하이리턴
                <br />
                <br />
                가장 안전하게 자산을 보호하고
                <br />
                가장 확실하게 수익을 얻는방법
                <br />
                12%의 확정이자를 약속합니다.
              </p>
              <a href="#services" className="btn btn-v02 btn-rounded">
                시작하기
              </a>
            </div>
          </div>
        </div>
      </section> */}
      {/* END WHAT WE DO */}
      {/* About us */}
      {/* <section>
        <div className="container">
          <div className="row">
            <div className="col-lg-5 col-md-6 col-sm-12 m-b-60 m-t-30">
              <h2 className="tit-v03">
                간편결제는 있는데
                <br />
                간편매매는 없는건가?
              </h2>
              <p className="lead">
                뭐든지 간편해질 수 있는 세상
                <br />
                이제 간편하게 당신의 시간을 지키세요.
                <br />
                <br />
                간편매수로 원하는 자산을 제한없이.
                <br />
                간편매도로 원하는 자산을 제한없이.
                <br />
                {SITE_NAME} 간편매매 서비스.
              </p>
              <a href="#services" className="btn btn-v03 btn-rounded">
                시작하기
              </a>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 t-a-c bg-f8">
              <p className="p-t-20">
                <img alt="img" src={img03} />
              </p>
            </div>
          </div>
        </div>
      </section> */}
      {/* end: About us */}
      {/* About us */}
      {/* <section id="menu1">
        <div className="container">
          <div className="heading-text heading-section">
            <h2>전략투자</h2>
            <div className="row text-right">
              <div className="col-lg-5 col-md-6 col-sm-12">
                <p className="lead">
                  <img alt="img" src={imgBa1} />
                </p>
              </div>
              <div className="col-lg-6 col-md-6 col-sm-12 t-a-c">
                <p className="">
                  <img alt="img" src={imgBa2} />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* end: About us */}
      {/* Our numbers */}
      {/* <section className="background-grey p-t-60 p-b-50">
        <div className="container xs-text-center sm-text-center">
          <div className="row confirmed">
            <div className="col-lg-3 col-md-6 col-sm-6 m-b-15">
              <div className="text-center">
                <p className="box-02">
                  <span>연간수익</span> 124.74%
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 m-b-15">
              <div className="text-center">
                <p className="box-02">
                  <span>단기최대</span> 36.75%
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 m-b-15">
              <div className="text-center">
                <p className="box-02 fon-col-00A">
                  <span>최대낙폭</span> -31.10%
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 m-b-15">
              <div className="text-center">
                <p className="box-02 text-col-000">
                  <span>운용일수</span> 365일
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* end: Our numbers */}
      {/* PORTFOLIO */}
      {/* <section className="p-b-0">
        <div className="container">
          <div className="heading-text heading-section">
            <h2>전략 투자 운용개요</h2>
            <span className="lead">
              {SITE_NAME}이 독자 개발한 BA (Bollinger Ai) 알고리즘 시스템에, 뉴런
              빅데이터 분석을 통해 도출한 분석결과를 대입하여 도출된
              포지셔닝대로 매수, 매도를 Time to go 전략에 맞춰 실행하는 트레이닝
              기법입니다.
              <br />
              <br />
              일반적인 매수, 매도의 수익창출 및 손실방어를 위한 헷지 전략
              그리고, 거래소 간 가격 차이를 이용한 재정거래 등의 수익의 극대화를
              위한 다양한 트레이닝 기법들을 적재적소에 활용합니다.
              <br />
              <br />
              <span className="fon-col-00A">
                단, {SITE_NAME}의 수익성을 장기적으로 보장하기 위해, 세부 전략은
                공개하지 않습니다.
              </span>
            </span>
          </div>
        </div>
      </section> */}
      {/* end: PORTFOLIO */}
      {/* <section className="p-b-0">
        <div className="container">
          <div className="row  m-b-50">
            <div className="col-lg-3">
              <div className="heading-text heading-section">
                <h2>거래대상 및 성과지표</h2>
              </div>
            </div>
            <div className="col-lg-9">
              <span className="lead">
                oUSDT로 다른 디지털자산 가상화폐에 투자하게 되며, 거래소는 국내
                거래소 및 해외 마진 거래소를 활용합니다.
                <br />
                고객님의 투자 후 투자성과 리포트는 매월 1일에 리포팅 하여 지정한
                e-mail로 전송합니다.
                <br />
                투자 후 발생한 전월의 투자수익금은 고객이 지정한 지갑으로{" "}
                <span className="fon-col-00A">매월 7일 이내 oUSDT로 전송</span>
                됩니다.
              </span>
            </div>
          </div>
        </div>
      </section> */}
      {/* <section>
        <div className="container">
          <div className="heading-text text-center">
            <h2>전략 투자 진행방법</h2>
            <p className="lead text-center">
              oUSDT를 {SITE_NAME}이 지정한 지갑으로 이체한 다음날부터 전략 투자를
              실행합니다.
              <br />
              최소 투자금은 <span className="fon-col-00A">10,000 oUSDT</span>
              부터 100 oUSDT 단위로 가능합니다.
            </p>
          </div>
          <div className="">
            <p className="lead">
              oUSDT를 {SITE_NAME}이 지정한 지갑으로 이체한 다음날부터 전략 투자를
              실행합니다.
              <br />
              최소 투자금은 10,000 oUSDT부터 1,000 oUSDT 단위로 가능합니다.
              투자금만 내세요. {SITE_NAME}의 전략 투자로 수익을 내어, 수익금을
              따박따박 편하게 송금해 드립니다.{" "}
              <span className="fon-col-00A">
                단, 본 전략 투자는 손실이 발생할 수 있으며, 손실에 대한 책임은
                지지 않습니다.
              </span>
            </p>
          </div>
          <div className="plan-list p-t-20">
            <ul>
              <li>
                <em>1</em>전략 투자할 oUSDT를 준비합니다. (직접 준비 or{" "}
                <span className="fon-col-00A">코인 간편구매</span>를 통한 준비)
              </li>
              <li>
                <em>2</em>전략투자 시작하기 버튼을 누르고, 투자 약정서를
                작성합니다.
              </li>
              <li>
                <em>3</em>
                <span className="fon-col-00A">
                  준비한 oUSDT를 {SITE_NAME}이 지정한 지갑으로 전송합니다.
                </span>
              </li>
              <li>
                <em>4</em>익월부터 매월 1일 투자 성과 리포트와 발생한 수익금을
                지정 지갑으로 전송받습니다.
              </li>
              <li>
                <em>5</em>중도 해지를 원하는 경우, 고객지원으로 문의 바랍니다.
              </li>
            </ul>
          </div>
        </div>
      </section> */}
      {/* Our numbers */}
      {/* <section
        className="p-t-100 p-b-100 bg-style-custom-top"
        data-bg-parallax={img4}
      >
        <div className="bg-overlay custom-ovl" />
        <div className="container xs-text-center sm-text-center text-light">
          <div className="row">
            <h2 className="m-b-30">운용 비용</h2>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 m-b-30">
                  <div className="flo-l w-100p expense">
                    <p className="flo-l w-50p bg-00A">기본 수수료</p>
                    <div className="flo-l w-50p text-center">0%</div>
                  </div>
                </div>
                <div className="col-lg-6 m-b-30">
                  <div className="flo-l w-100p expense">
                    <p className="flo-l w-50p bg-00A">성과 수수료</p>
                    <div className="flo-l w-50p text-center">수익금의 25%</div>
                  </div>
                </div>
                <div className="col-lg-6 m-b-30">
                  <div className="flo-l w-100p expense">
                    <p className="flo-l w-50p bg-00A">중도해지 수수료</p>
                    <div className="flo-l w-50p text-center">수익금의 30%</div>
                  </div>
                </div>
                <div className="col-lg-6 m-b-30">
                  <div className="flo-l w-100p expense">
                    <p className="flo-l w-50p bg-00A">발생 세금</p>
                    <div className="flo-l w-50p text-center">비과세</div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="flo-l w-100p expense">
                    <p className="flo-l w-50p bg-00A w-270p">수익금 성과보상</p>
                    <div className="flo-l w-50p text-center w-100p-499">
                      고객 지정 지갑으로 매월 oUSDT 전송
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* end: Our numbers */}
      {/* <div className="invest col-lg-12 m-t-50 text-center">
        <h2>지금 바로 투자를 시작해 보세요</h2>
        <div>
          <a href="#" className="btn btn-danger">
            전략투자 시작하기
          </a>
        </div>
      </div> */}
      {/* About us */}
      {/* <section id="menu2">
        <div className="container">
          <div className="row text-right">
            <div className="col-lg-5 col-md-6 col-sm-12">
              <h2 className="tit-v00">
                예금말고 예치하자
                <br />연<span className="fon-col-fe">
                  {" "}
                  12.00% 확정이자
                </span>{" "}
                지급
              </h2>
              <p className="lead text-right">
                디지털 자산 기반의 자율금융 프로젝트
                <br />
                <span className="fon-col-fe">
                  매월 1회 확정이자 지급 &amp; 원금보호
                </span>
                <br />
                <br />
                리스크 전혀 없이 고객님의 원금을 보호합니다
                <br />
                1,2금융권의 예금과는 차원이 다른 연 12% 확정이자
                <br />
                원금까지 예수보호받을 수 있는 유일한 방법
                <br />
                보다 안정적인 수익을 추구하신다면
                <br />
                예금말고 예치하자
              </p>
            </div>
            <div className="col-lg-6 col-md-6 col-sm-12 t-a-c">
              <p className="">
                <img alt="img" src={img05} />
              </p>
            </div>
          </div>
          <div className=""></div>
        </div>
      </section> */}
      {/* end: About us */}
      {/* Our numbers */}
      {/* <section className="background-grey p-t-60 p-b-50">
        <div className="container xs-text-center sm-text-center">
          <div className="row confirmed">
            <div className="col-lg-3 col-md-6 col-sm-6 m-b-15">
              <div className="text-center">
                <p className="box-01">
                  <span>확정이자</span> 12.00%
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 m-b-15">
              <div className="text-center">
                <p className="box-01">
                  <span>운용기간</span> 365일
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 m-b-15">
              <div className="text-center">
                <p className="text-col-000">
                  <i
                    className="fas fa-caret-square-down"
                    style={{ fontSize: 28 }}
                  />{" "}
                  No risk
                </p>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-6 m-b-15">
              <div className="text-center">
                <p className="text-col-000">
                  <i
                    className="fas fa-caret-square-up"
                    style={{ fontSize: 28 }}
                  />{" "}
                  High return
                </p>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* end: Our numbers */}
      {/* PORTFOLIO */}
      {/* <section className="p-b-0">
        <div className="container">
          <div className="heading-text heading-section">
            <h2>예치보상 운용개요</h2>
            <span className="lead">
              디지털 자산 가상화폐를 그냥 가지고 있으면 꿔다 놓은 보릿자루에
              불과합니다.
              <br />
              보유하고 계신 디지털 자산 가상화폐를 {SITE_NAME}에 예치하시면, <br />
              상품 가입 시 고시한 보상기준에 따라 그{" "}
              <span className="fon-col-fe">이자를 매월마다 지급</span>합니다.
              <br />
              심지어, 고객님이 예치한 디지털 자산{" "}
              <span className="fon-col-fe">
                가상화폐의 원금은 100% 예수보호
              </span>{" "}
              받게 됩니다.
            </span>
          </div>
        </div>
      </section> */}
      {/* end: PORTFOLIO */}
      {/* PORTFOLIO */}
      {/* <section className="">
        <div className="container">
          <div className="heading-text heading-section">
            <h2>예치보상 운용개요</h2>
            <span className="lead">
              oUSDT를 {SITE_NAME}이 지정한 지갑으로 이체한 다음날부터 전략 투자를
              실행합니다.
              <br />
              최소 투자금은<span className="fon-col-fe"> 10,000 oUSDT</span>
              부터 1,000 oUSDT 단위로 가능합니다.
              <br />
              투자금만 내세요.
              <br />
              {SITE_NAME}이 약속한 확정이자 수익금을 따박따박 편하게 송금해
              드립니다.
              <br />
              <span className="fon-col-fe">
                본 예치보상 프로젝트 진행 시, 원금은 100% 온전히 예수
                보호됩니다.
              </span>
            </span>
          </div>
          <div className="plan-list p-t-20">
            <ul>
              <li>
                <em>1</em>전략 투자할 oUSDT를 준비합니다. (직접 준비 or{" "}
                <span className="fon-col-fe">코인 간편구매</span>를 통한 준비)
              </li>
              <li>
                <em>2</em>전략투자 시작하기 버튼을 누르고, 투자 약정서를
                작성합니다.
              </li>
              <li>
                <em>3</em>
                <span className="fon-col-fe">
                  준비한 oUSDT를 {SITE_NAME}이 지정한 지갑으로 전송합니다.
                </span>
              </li>
              <li>
                <em>4</em>익월부터 매월 1일 투자 성과 리포트와 발생한 수익금을
                지정 지갑으로 전송받습니다.
              </li>
              <li>
                <em>5</em>중도 해지를 원하는 경우, 고객지원으로 문의 바랍니다.
              </li>
            </ul>
          </div>
        </div>
      </section> */}
      {/* end: PORTFOLIO */}
      {/* Our numbers */}
      {/* <section
        className="p-t-100 p-b-100 bg-style-custom-bottom"
        data-bg-parallax={img4}
      >
        <div className="bg-overlay custom-ovl" />
        <div className="container xs-text-center sm-text-center text-light">
          <div className="row">
            <h2 className="m-b-30">운용 비용</h2>
            <div className="col-lg-12">
              <div className="row">
                <div className="col-lg-6 m-b-30">
                  <div className="flo-l w-100p expense">
                    <p className="flo-l w-50p bg-fe">월간 확정이자</p>
                    <div className="flo-l w-50p text-center">1.00%</div>
                  </div>
                </div>
                <div className="col-lg-6 m-b-30">
                  <div className="flo-l w-100p expense">
                    <p className="flo-l w-50p bg-fe">연간 확정이자</p>
                    <div className="flo-l w-50p text-center">12.00%</div>
                  </div>
                </div>
                <div className="col-lg-6 m-b-30">
                  <div className="flo-l w-100p expense">
                    <p className="flo-l w-50p bg-fe">중도해지 수수료</p>
                    <div className="flo-l w-50p text-center">
                      발생 이자의 10%
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 m-b-30">
                  <div className="flo-l w-100p expense">
                    <p className="flo-l w-50p bg-fe">발생 세금</p>
                    <div className="flo-l w-50p text-center">비과세</div>
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="flo-l w-100p expense">
                    <p className="flo-l w-50p bg-fe w-270p">발생이자 보상</p>
                    <div className="flo-l w-50p text-center">
                      고객 지정 지갑으로 매월 oUSDT 전송
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section> */}
      {/* end: Our numbers */}
      {/* <div className="invest col-lg-12 m-t-50 m-b-60 text-center">
        <h2>지금 바로 예치를 시작해 보세요</h2>
        <div>
          <a href="#" className="btn btn-danger">
            예치보상 시작하기
          </a>
        </div>
      </div> */}
      {/* </S.Wrapper> */}
    </>
  );
}

export default Homepage;
