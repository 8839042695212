import { Button, Modal, ModalProps } from "antd";
import * as S from "./style";

interface ApproveWarningModalProps extends ModalProps {
  handleOk: () => void;
}

const ApproveWarningModal: React.FC<ApproveWarningModalProps> = ({
  handleOk,
  ...props
}) => {
  return (
    <Modal
      {...props}
      centered
      width={500}
      title={
        <S.ModalTitle className="modal-title">
          계정이 승인 대기 중입니다.
        </S.ModalTitle>
      }
      style={{
        padding: "32px 28px 20px 28px",
      }}
      footer={[
        <div style={{ display: "flex", justifyContent: "center" }}>
          <Button
            onClick={handleOk}
            className="btn btn-v02 btn-rounded no-padding"
            style={{ width: 100, height: 40 }}
          >
            확인
          </Button>
        </div>,
      ]}
    />
  );
};

export default ApproveWarningModal;
