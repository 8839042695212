import React, { useState } from "react";
import * as S from "./style";
import PageTitle from "components/PageTitle";
import bgTitleImg from "assets/images/4.jpg";
// import { useHistory } from "react-router-dom";

import imgInfo1 from "assets/images/identity/imgInfo1.png";
import imgInfo2 from "assets/images/identity/imgInfo2.png";
import imgInfo3 from "assets/images/identity/imgInfo3.png";
import img4 from "assets/images/identity/img4.jpg";
import { SITE_NAME } from "constant/configs";

function Identity() {
  // const history = useHistory();
  // const [isChecked, setIsChecked] = useState<boolean>(false);

  // const gotoSellForm = () => {
  //   if (isChecked) {
  //     history.push("/purchase-form");
  //   }
  // };

  return (
    <React.Fragment>
      <PageTitle backgroundImage={bgTitleImg} title="본인인증" />
      <S.Wrapper>
        <div className="container">
          <div className="heading-text heading-section text-center">
            <h2>본인인증 절차</h2>
            <p>
              최초 1회만 인증하면 됩니다. 불편하시더라도 부탁드립니다.
              <br />
              인증 이후 지갑, 계좌등의 변경이 생긴경우에는 다시 인증하셔야
              합니다.
            </p>
            <p>
              <button className="btn btn-lg btn-warning btn-oUSDT-kyc">
                사진업로드
              </button>
            </p>
          </div>
          <div className="row line-block">
            <div className="col-lg-7 col-md-6 col-sm-12">
              <div className="t01">
                <span className="num">1</span>
                <span className="t01-title">900만원이하 (통장확인)</span>
              </div>
              <div className="t02">
                아래 설명에 따라 [통장확인] 이미지를 첨부해주세요.
              </div>
              <p className="t03">
                계좌번호, 예금주 등이 적힌 통장의 맨 앞장을 사진 촬영 합니다.
                <br />
                인터넷 뱅킹을 이용 하시는 경우, [통장표지 출력 서비스]로 화면
                캡쳐합니다.
                <br />
                모바일 뱅킹을 이용하는 경우, 예금주와 계좌번호가 보이는 [계좌
                화면]을 캡쳐합니다.
              </p>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12">
              <div className="img-box">
                <div className="tbox">
                  <strong>
                    계좌번호, 예금주 등이 적힌 통장의 맨 앞장을 촬영
                  </strong>
                </div>
                <img src={imgInfo1} title="" className="image-demo" alt="" />
              </div>
            </div>
          </div>
          <div className="row line-block">
            <div className="col-lg-7">
              <div className="t01">
                <span className="num">2</span>
                <span className="t01-title">
                  900만원초과 1500만원이하 (신분증확인)
                </span>
              </div>
              <div className="t02">
                아래 설명에 따라 [신분증] 이미지를 첨부해주세요.
              </div>
              <p className="t03">
                신분증에 인증 목적, 전화번호, 날짜를 메모해서 붙이고, 신분증이
                잘 보이게 사진 촬영 또는 스캔합니다.
                <br />
                주민등록번호 뒤 7자리는 가려주세요.
                <br />
                사진 촬영 시간을 기준으로 제출일 이전에 찍은 사진은 인정하지
                않습니다.
              </p>
            </div>
            <div className="col-lg-5">
              <div className="img-box">
                <div className="tbox">
                  <strong>신분증 사진</strong>
                  <p className="txt">
                    신분증 글자(메모 포함)를 읽을 수 있어야 합니다.
                  </p>
                </div>
                <img src={imgInfo2} title="" className="image-demo" alt="" />
              </div>
            </div>
          </div>
          <div className="row line-block">
            <div className="col-lg-7">
              <div className="t01">
                <span className="num">3</span>
                <span className="t01-title">
                  1500만원초과 (비대면 본인확인)
                </span>
              </div>
              <div className="t02">
                아래 설명에 따라 [비대면 본인확인] 이미지를 첨부해주세요.
              </div>
              <p className="t03">
                신분증에 인증 목적, 전화번호, 날짜를 기입한 메모를 부착해서
                촬영하세요. 사진 촬영 시간을 기준으로 제출일 이전에 찍은 사진은
                인정하지 않습니다. 신분증 글자(메모 포함)를 읽을 수 있을 정도로
                해상도가 높아야합니다.
                <br />
                <br />
                신분증에 {SITE_NAME} 인증요청이란 문구와 인증 목적, 전화번호,
                날짜를 메모해서 붙이고,신분증이 잘 보이게 사진 촬영 또는
                스캔합니다.주민등록번호 뒤 7자리는 가려주세요. <br />
                <br />
                신분증의 본인과 실제로 요청중인 사람이 일치하는지 담당자가
                확인합니다. 비대면 실명확인을 위한 절차이오니 불편하시더라도
                따라 주시기 바랍니다. 받은 사진은 해당 요청에 대한 비대면
                실명확인 용도로만 사용합니다.
              </p>
            </div>
            <div className="col-lg-5">
              <div className="img-box">
                <div className="tbox">
                  <strong>신분증을 들고 있는 본인 사진</strong>
                  <p className="txt">
                    신분증 글자(메모 포함)를 읽을 수 있어야 합니다.
                  </p>
                </div>
                <img src={imgInfo3} title="" className="image-demo" alt="" />
              </div>
            </div>
          </div>
          <div className="m-t-40">
            <div className="title">
              <p className="title-bottom t-a-c">
                메일로 보내실 경우 <span>doohun79@gmail.com</span>로 보내주시면
                됩니다.
              </p>
            </div>
            <div className="row box-1">
              <div className="col-lg-6">
                <img src={img4} title="" alt="" />
              </div>
              <div className="col-lg-6">
                <div className="content-right">
                  <p className="t01">메일 제목</p>
                  <div className="text-05">
                    {SITE_NAME} 인증 자료를 제출합니다.
                  </div>
                  <p className="t01">메일 내용</p>
                  <div className="text-05">
                    - 이름 :<br />- 전화번호 :<br />
                    첨부파일
                    <br />
                    1. 신분증을 들고 있는 본인 사진 (비대면 실명 확인)
                    <br />
                    <br />
                    감사합니다.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default Identity;
