import * as S from "./style";
import PageTitle from "components/PageTitle";
import bgTitleImg from "assets/images/4.jpg";
import React, { useEffect } from "react";
import { DOMAIN_NAME, SITE_NAME } from "constant/configs";

function TermOfUse() {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <React.Fragment>
      <PageTitle backgroundImage={bgTitleImg} title="이용약관" />
      <S.Wrapper>
        <div className="container sub-page inquire">
          <div className="heading-text heading-section">
            <h2>이용약관</h2>
          </div>
          <S.Content>
            <div
              dangerouslySetInnerHTML={{
                __html: `<p><strong><span style="font-size:16pt;">제1장 총칙</span></strong></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제1조 (목적)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">본 약관은 ${SITE_NAME} (이하 &ldquo;회사&rdquo;라 합니다)이 제공하는 ${DOMAIN_NAME} 에서의 가상자산 구매 &middot; 판매 대행과 관련한 제반 서비스의 이용조건 및 절차에 관하여 회사와 회원 간의 권리 &middot; 의무 및 책임사항, 기타 필요한 사항을 규정함을 목적으로 합니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제2조 (용어의 정의)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">본 약관에서 사용하는 용어의 정의는 다음과 같습니다.</span></p>
<p><span style="font-size:11pt;">1. &quot;서비스&quot;라 함은 구현되는 전자적 장치와 상관없이 회사가 사이트를 통해 회원에게 제공하는 가상자산 투자 및 거래서비스, 기타 관련 제반 서비스를 의미합니다.</span></p>
<p><span style="font-size:11pt;">2. &quot;회원&quot;이라 함은 회사에 회원가입 하여 본 약관에 따라 회사와 서비스 이용계약을 체결한 자를 의미합니다.</span></p>
<p><span style="font-size:11pt;">3. &quot;아이디(ID 또는 계정)&quot;라 함은 회원의 식별과 서비스 이용을 위하여 회원이 정하고 회사가 승인하는 문자 또는 숫자의 조합을 의미합니다.</span></p>
<p><span style="font-size:11pt;">4. &quot;비밀번호&quot;라 함은 회원이 부여받은 아이디와 일치되는 회원임을 확인하고 비밀보호를 위해 회원 자신이 정한 문자 또는 숫자의 조합을 말합니다.</span></p>
<p><span style="font-size:11pt;">5. &quot;가상자산&quot;이라 함은 「가상자산 이용자 보호 등에 관한 법률」(이하 &quot;가상자산이용자보호법&quot;이라 합니다) 제2조 제1호에 따른 전자적 증표를 의미합니다.&nbsp;</span></p>
<p><span style="font-size:11pt;">6. &quot;실명확인입출금계정&quot;이라 함은 회사가 실명확인입출금 서비스 관련 계약을 체결한 금융기관에 개설된 회사의 계좌와 동일 금융회사의 회원의 계좌 사이에서만 금융거래 등을 허용하는 계정을 의미합니다.</span></p>
<p><span style="font-size:11pt;">7. &quot;지갑주소&quot;라 함은 회사가 소유, 통제 또는 운영하지 아니하는 주소로서 서비스 외부에서 회원 또는 제3자가 소유하거나 사용하는 모든 가상자산 지갑 주소를 의미합니다.&nbsp;</span></p>
<p><span style="font-size:11pt;">8. &quot;전자적 장치&quot;라 함은 회원이 서비스를 이용하는데 필요한 장치로서 컴퓨터, 휴대용 단말기 등 각종 유무선 장치를 의미합니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제3조 (약관의 명시, 설명과 개정)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">① 회사의 서비스 제공 행위 및 회원의 서비스 사용 행위에는 본 약관이 적용됩니다.&nbsp;</span></p>
<p><span style="font-size:11pt;">② 회사는 본 약관을 변경하고자 하는 경우에는 30일 전에 그 내용을 회사가 제공하는 웹페이지 등을 통해 게시하고 회원에게 통지하여야 합니다. 다만, 회원이 이의를 제기할 경우 회사는 회원에게 적절한 방법으로 약관 변경내용을 통지하였음을 확인해 주어야 합니다.&nbsp;</span></p>
<p><span style="font-size:11pt;">③ 제2항에도 불구하고 법령 또는 행정규칙의 개정으로 인하여 긴급하게 약관을 변경한 때에는 변경된 약관을 회사가 제공하는 웹페이지에 최소 30일 이상 게시하고 회원에게 통지하여야 합니다.</span></p>
<p><span style="font-size:11pt;">④ 회사가 제2항 및 제3항에 따라 개정약관을 개별적으로 통지하면서 회원에게 적용일자 전날까지 동의여부 확답을 발송할 것을 요청하고 이의를 제기하지 아니하는 경우 약관 개정에 동의한 것으로 본다는 취지를 명확하게 고지하였음에도 회원이 명시적으로 거부의 의사표시를 하지 아니한 경우 회원이 개정약관에 동의한 것으로 봅니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제4조 (약관 외 준칙)</span></strong></p>
<p><span style="font-size:11pt;">회원은 본 약관에 규정되지 않은 사항에 대해서는 관련법령, 상관례 또는 개별 서비스의 이용약관에 따릅니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:16pt;">제2장 이용계약의 체결 (회원가입)</span></strong></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제5조 (이용계약의 체결)</span></strong></p>
<p><span style="font-size:11pt;">① 이용계약은 회원이 되고자 하는 자(이하 &quot;가입신청자&quot;라 합니다)가 본 약관의 내용을 동의하고 회사가 정한 절차에 따라 가입신청을 완료한 후 회사가 회원 가입을 승낙하면 성립됩니다.&nbsp;</span></p>
<p><span style="font-size:11pt;">② 이용계약의 성립 시기는 회사가 계정 생성 완료를 신청 절차 상에서 표시하거나 회사의 승낙이 회원에게 도달한 시점으로 합니다.&nbsp;</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제6조(고객확인의무)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">① 회사는 회원이 제공한 정보가 사실과 일치하는지 여부를 확인하기 위하여 가입신청자에게 법령에 의해 허용된 범위에서 실명확인 또는 본인인증, 금융거래 등의 목적 및 거래자금의 원천 등에 관하여 요청할 수 있습니다. 고객확인에 필요한 정보 &middot; 문서 &middot; 자료 등에 관하여는 관련 법령 및 회사의 개인정보처리방침을 따릅니다.</span></p>
<p><span style="font-size:11pt;">② 회사는 「특정금융정보법」 및 관련 법령에서 정한 가상자산사업자 의무 이행을 위하여 회원 정보, 거래 내역 등을 제3자(금융정보분석원, 타가상자산사업자 등 관련 법령에서 정한 기관)에 제공할 수 있습니다.</span></p>
<p><span style="font-size:11pt;">③ 회사는 회원이 신원확인 제공을 거부하는 등 고객확인을 할 수 없거나 거래 목적 또는 자금의 원천을 확인할 수 없는 경우 해당 회원과의 신규 거래를 거절하고 이미 거래관계가 수립되어 있는 경우에는 이용계약을 해지할 수 있습니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제7조 (회원 정보 관리)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">① 회사는 회원이 등록한 계정과 비밀번호 등이 회사에 등록된 것과 일치할 경우에는 별도의 확인절차 없이 이용자를 회원으로 간주합니다.</span></p>
<p><span style="font-size:11pt;">② 회원의 아이디, 비밀번호 등의 계정 접근정보에 관한 모든 관리책임은 회원에게 있으며, 회원은 계정 접근정보를 타인에게 양도, 대여할 수 없습니다.&nbsp;</span></p>
<p><span style="font-size:11pt;">③ 회원은 자신의 아이디, 비밀번호가 부정하게 사용된 사실을 알게 될 경우 반드시 회사에 그 사실을 통지하고 회사의 안내에 따라야 합니다.</span></p>
<p><span style="font-size:11pt;">④ 제3항의 경우에 해당 회원이 회사에 그 사실을 통지하지 않거나, 통지한 경우에도 회사의 안내에 따르지 않아 발생한 불이익에 대하여 회사는 책임지지 않습니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제8조 (개인정보의 보호)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">회사는 「정보통신망 이용촉진 및 정보보호 등에 관한 법률」(이하 &apos;정보통신망법&apos;이라 합니다), 「개인정보보호법」 등 관련 법령이 정하는 바에 따라 회원의 개인정보를 보호하기 위해 노력합니다. 개인정보의 보호 및 이용에 대해서는 관련 법령 및 회사의 개인정보처리방침이 적용됩니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제9조 (권리의 귀속)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">① 회사의 서비스와 관련된 일체의 지식재산권은 회사에게 속합니다.</span></p>
<p><span style="font-size:11pt;">② 회원은 본 서비스 이용 과정에서 얻은 정보를 가공, 제공, 판매하는 등 영리 목적으로 이용하거나 제3자에게 이용하게 할 수 없습니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:16pt;">제3장 서비스의 이용</span></strong></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제10조 (서비스의 제공 및 중지)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">① 서비스의 이용은 회사의 업무상 또는 기술상 특별한 지장이 없는 한 연중무휴 1일 24시간을 원칙으로 합니다.</span></p>
<p><span style="font-size:11pt;">② 회사는 다음 각 호의 하나에 해당하는 경우 서비스의 전부 또는 일부를 중지, 제한 또는 종료할 수 있습니다.</span></p>
<p><span style="font-size:11pt;">1. 서비스용 설비의 정기점검 및 보수점검, 교체 및 고장 등 운영상 합리적인 이유가 있는 경우&nbsp;</span></p>
<p><span style="font-size:11pt;">2. 전기통신사업법에 규정된 기간통신사업자가 전기통신 서비스를 중지했을 경우</span></p>
<p><span style="font-size:11pt;">3. 회사의 서비스에 대한 전망 악화 등 경영상 문제 또는 블록체인 네트워크 불안정 및 장애, 코드 오류 등 기술상 문제로 인하여 서비스를 제공하기 어려운 경우</span></p>
<p><span style="font-size:11pt;">4. 회사 또는 제휴사의 영업양도, 분할, 합병, 해산, 파산 등에 따른 영업의 종료 또는 제휴사의 영업중단의 경우</span></p>
<p><span style="font-size:11pt;">5. 해킹</span></p>
<p><span style="font-size:11pt;">6. 가상자산의 기반 기술에 취약성이 발견되는 경우</span></p>
<p><span style="font-size:11pt;">7. 서비스의 사용 사례가 법령에 위반되거나 공공질서 및 미풍양속에 저해되는 경우</span></p>
<p><span style="font-size:11pt;">8. 서비스에 대한 회원들의 민원 또는 불만이 계속적으로 접수되는 경우</span></p>
<p><br></p>
<p><span style="font-size:11pt;">9. 관련 법령에 위반되거나 서비스 이용과 관련하여 정부 기관 또는 유관 기관의 지시 또는 운영상 어려움이 있는 경우</span></p>
<p><span style="font-size:11pt;">10. 국가비상사태, 정전 및 천재지변 등 기타 불가항력적 사유가 있는 경우</span></p>
<p><span style="font-size:11pt;">③ 회사는 제2항의 경우 사전에 서비스 초기화면이나 공지사항을 통하여 회원에게 통지합니다. 단, 회사가 사전에 통지할 수 없는 경우 사후에 통지할 수 있습니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제11조(서비스의 변경)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">① 회사는 안정적인 서비스 제공을 위하여 서비스의 내용 및 운영상, 기술상 사항 등을 변경할 수 있습니다.&nbsp;</span></p>
<p><span style="font-size:11pt;">② 회사는 서비스를 변경할 경우 변경내용과 적용일자를 명시하여 사전에 공지합니다. 단, 회사가 사전에 공지할 수 없는 부득이한 사유가 있는 경우 사후에 공지할 수 있습니다.</span></p>
<p><span style="font-size:11pt;">③ 회원은 서비스 변경에 동의하지 않을 경우 회사에 거부 의사를 표시하고 이용계약을 해지할 수 있습니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제12조(가상자산 거래지원 서비스)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">① 회원은 서비스 이용을 위해 실명확인입출금계정을 개설 및 등록해야 할 수 있습니다.</span></p>
<p><span style="font-size:11pt;">② 회원은 실명확인입출금계정을 통해 원화를 입&middot;출금 할 수 있고, 전자지갑주소를 통해 가상자산을 매수&middot;매도 할 수 있습니다. 단, 1일 입&middot;출금 한도는 실명확인입출금계정 발급 은행 또는 회사의 정책에 따라 회원별로 다를 수 있습니다.</span></p>
<p><span style="font-size:11pt;">③ 회원은 회사의 가상자산 예치서비스를 통해 가상자산을 운영하고 전략투자 할 수 있습니다. 이 경우 회원의 원금은 온전히 보호하며 회사가 정한 방법에 따라 이자를 지급합니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제13조(서비스 이용관련 유의사항)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">① 회사는 회사의 거래지원 심사 위원회의 결정으로 가상자산의 거래를 지원하거나 종료할 수 있습니다. 회사는 가상자산의 거래지원을 종료하는 경우 회원에게 통지하고 회사가 지원하는 블록체인 네트워크 범위 내에서 회원이 예치한 가상자산을 이전할 수 있도록 지원합니다.</span></p>
<p><span style="font-size:11pt;">② 회원이 가상자산의 구매 또는 판매 신청을 완료한 후에는 해당 내용을 수정 또는 취소할 수 없습니다. 단, 서비스의 전부 또는 일부의 변경으로 인하여 거래지원이 종료되는 가상자산의 경우 해당 가상자산에 관한 주문은 거래지원 종료일까지 체결되지 아니하면 자동취소 됩니다.</span></p>
<p><span style="font-size:11pt;">③ 회원은 회사의 서비스와 관련하여 다음 각 호의 사항을 이해하고 이용하여야 합니다.</span></p>
<p><span style="font-size:11pt;">1. 회원의 가상자산 구매 &middot; 판매 신청에 따른 가상자산 전송은 회사와 무관하게 해당 가상자산이 속한 블록체인 네트워크에서 이루어집니다.</span></p>
<p><span style="font-size:11pt;">2. 회원의 서비스 신청에 따른 가상자산 전송은 해당 가상자산이 속한 블록체인 네트워크에서 필요한 절차를 이행하는 데 요구되는 시간이 경과한 후 완료됩니다.</span></p>
<p><span style="font-size:11pt;">3. 회사는 회원이 가상자산 거래 서비스를 이용함에 있어서 필요한 최소 신청 수량 및 최소 입출금 수량을 변경할 수 있습니다.</span></p>
<p><span style="font-size:11pt;">④ 회원은 서비스 신청 시 가상자산의 종류와 금액 지갑주소를 정확하게 확인하여야 합니다. 부정확할 경우 정상 입출금 처리되지 않습니다. 단, 회사는 보안상 위험 요소가 없거나 현저히 저감되었고 해당 오입금 복구지원이 기술적, 경제적으로 가능하다는 회사의 합리적인 판단이 있을 경우 해당 오입금 건을 복구지원할 수 있으며, 이 경우 복구를 요청한 회원에게 합리적인 수준의 복구 비용을 청구할 수 있습니다.</span></p>
<p><span style="font-size:11pt;">⑤ 회사는 영업양도, 분할, 합병 등에 따른 영업의 종료, 현저한 수익 악화 등 경영상의 중대한 사유로 서비스를 지속하기 어려운 경우에는 서비스 전부를 중단할 수 있습니다. 이 경우 회원에게 통지하고 회원이 예치한 가상자산을 이전할 수 있도록 지원합니다.</span></p>
<p><span style="font-size:11pt;">⑥ 본 약관에 따른 서비스 이용 제한, 거래지원 종료, 실명확인입출금계정 해제, 회사의 정책 및 그에 준하는 거래를 할 수 없는 사정이 발생하는 경우 회원의 자산보호를 위하여 미체결 주문은 취소될 수 있습니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제14조 (예치금)</span></strong></p>
<p><span style="font-size:11pt;">① 회사는 회원으로부터 가상자산의 구매 &middot; 판매 &middot; 예치 및 그 밖의 영업행위와 관련하여 예치 받은 금전(이하 &ldquo;예치금&quot;)을 고유재산과 분리하여 은행 등 법령에서 정하는 공신력 있는 기관에 예치 또는 신탁하여 관리합니다.</span></p>
<p><span style="font-size:11pt;">② 회사는 예치금 이용료에 대한 산정기준 및 지급절차를 마련하고, 회원에게 예치금 이용료를 지급합니다. 단, 회사가 정하는 규정에 따라 회원에게 지급되는 이용료가 달라질 수 있습니다.</span></p>
<p><span style="font-size:11pt;">③ 본 조 제2항에도 불구하고 회사는 다음 각호와 같은 특별한 사정이 있는 경우 그 사정이 해소될 때까지 예치금 이용료 지급이 유예될 수 있습니다.</span></p>
<p><span style="font-size:11pt;">1. 회원이 본 약관에 따른 고객확인의무 절차를 이행하지 않은 경우</span></p>
<p><span style="font-size:11pt;">2. 회원이 본 약관에 따른 실명확인입출금계좌를 등록하지 않은 경우</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제15조 (서비스 수수료 등)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">① 회사는 회원의 서비스 이용에 따른 수수료를 부과할 수 있으며, 수수료는 홈페이지를 통하여 고지합니다. 단, 수수료는 회사 및 시장의 상황에 따라 변경될 수 있습니다.</span></p>
<p><span style="font-size:11pt;">② 관련 법령 또는 본 약관에 따라 입금 승인이 거절되어 반환조치를 위한 반환(출금) 수수료가 발생하는 경우 해당 수수료는 회원이 부담하여야 합니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:16pt;">제4장 이용계약의 종료</span></strong></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제16조 (이용계약의 해지)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">① 회원은 언제든지 홈페이지 고객지원 메뉴를 통하여 이용계약 해지를 신청할 수 있습니다.&nbsp;</span></p>
<p><span style="font-size:11pt;">② 회사는 회원에게 보편적 법령, 행정규칙 위반 또는 약관 위반행위를 하는 경우 이용계약을 해지할 수 있습니다.</span></p>
<p><span style="font-size:11pt;">③ 본 조에 의한 이용계약 해지 시, 회원은 회사에 예치 중인 자산을 모두 회사 외의 계좌 또는 지갑주소로 이전하여야 합니다. 이 경우 회사는 본인확인이 되지 않는 계좌 또는 지갑주소에 관하여 이전을 거부할 수 있습니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:16pt;">제5장 책임제한</span></strong></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제17조 (책임제한)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">① 회사 또는 회원은 본 약관을 위반하여 상대방에게 손해를 입힌 경우에는 그 손해를 배상할 책임이 있습니다. 다만, 행위자의 고의 또는 과실이 없는 경우에는 그러하지 아니합니다.</span></p>
<p><span style="font-size:11pt;">② 회사는 다음과 같은 사유로 회원에게 손해가 발생하였을 경우 책임을 지지 않습니다. 다만, 회사의 고의 또는 과실이 경합한 때에는 법에 따른 상당한 범위 내에서 그에 따른 책임이 면제되지 않습니다.</span></p>
<p><span style="font-size:11pt;">1. 전시, 사변, 천재지변 또는 이에 준하는 국가 비상 사태 등 불가항력적인 경우</span></p>
<p><span style="font-size:11pt;">2. 정부 기관 및 은행의 사실상 또는 법률상 행정처분 및 명령 등에 대한 준수로 인한 경우</span></p>
<p><span style="font-size:11pt;">3. 회사가 관리할 수 없는 외주 시스템의 하자 또는 회원의 고의 또는 과실로 인하여 서비스 장애 또는 손해가 발생한 경우</span></p>
<p><span style="font-size:11pt;">4. 전기통신사업법에 의한 기간통신사업자를 포함한 통신서비스 업체의 서비스 장애로 인한 경우</span></p>
<p><span style="font-size:11pt;">5. 서비스 내 거래시스템, 입출금 시스템, 각 가상자산 발행 관리 시스템 또는 가상자산이 속한 블록체인상의 하자로 인하여 서비스에 장애가 발생한 경우&nbsp;</span></p>
<p><span style="font-size:11pt;">6. 전산장애 또는 순간적인 홈페이지 접속 증가, 일부 종목의 주문 폭주 등으로 인한 서버의 장애가 발생한 경우</span></p>
<p><span style="font-size:11pt;">③ 제2항의 필요한 관리자의 주의란 다음 사항을 말합니다.</span></p>
<p><br></p>
<p><span style="font-size:11pt;">1. 정보보호 책임자 선임 및 관리</span></p>
<p><span style="font-size:11pt;">2. 정보보호 교육</span></p>
<p><span style="font-size:11pt;">3. 전산실, 전산실이 위치한 건물 내 시설, 정보보호 시스템 및 정보처리 시스템에 대한 보호 대책</span></p>
<p><span style="font-size:11pt;">4. 정보보안사고 대응 방안 수립</span></p>
<p><span style="font-size:11pt;">5. 정보보호 대책 및 보안사고 대응방안 등에 대한 정기적인 점검</span></p>
<p><span style="font-size:11pt;">6. 기타 정보보호 관련 법률에서 가상자산사업자에게 준수하도록 정하는 사항</span></p>
<p><span style="font-size:11pt;">④ 회사는 가상자산의 가격 변동 또는 회원의 송금 정보 오입력에 관하여 책임을 지지 않습니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:16pt;">제6장 기타</span></strong></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제18조 (대리 및 보증의 부인)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">① 회사는 회원을 대리할 권한을 갖고 있지 않으며, 회사의 어떠한 행위도 회원을 위한 대리 행위로 간주되지 않습니다.</span></p>
<p><span style="font-size:11pt;">② 회사는 본 약관에 명시되지 않은 어떠한 사항에 대하여도 약정이나 보증을 하지 않으며, 회사가 발행하지 아니하거나 회사가 지급을 보증하지 아니한 가상자산의 완전성 및 가치를 보증하지 아니합니다.</span></p>
<p><br></p>
<p><strong><span style="font-size:11pt;">제19조 (관할법원 및 준거법)</span></strong></p>
<p><br></p>
<p><span style="font-size:11pt;">① 회사와 회원 간에 발생한 분쟁에 관한 소송의 관할법원은 당사자들이 합의하여 결정하며 합의가 이루어지지 않을 때에는 민사소송법에 의한 법원으로 합니다.&nbsp;</span></p>
<p><span style="font-size:11pt;">② 서비스 이용과 관련하여 회사와 회원 간의 소송에는 대한민국법을 적용합니다.</span></p>`,
              }}
            />
          </S.Content>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default TermOfUse;
