import { apiConfig } from "api";
import { ApiUrl } from "constant/endpoint";

interface SellPayload {
  total_exchange: number;
  amount: number | string;
  user_address: string;
  bank_name: string;
  account_number: string;
  account_name: string;
  address_receiving: string;
  rate_sell_coin: string | number;
  rate_krw_usdt: string | number;
}

interface ListSellPayload {
  perPage: number;
  page: number;
  query: string;
  startTime: string;
  endTime: string;
}

export const sellApi = (payload: SellPayload) => {
  return apiConfig.request({
    url: ApiUrl.CREATE_SELL_COIN,
    method: "POST",
    data: payload,
    tokenRequired: true,
  });
};

export const listSellApi = (payload: ListSellPayload) => {
  return apiConfig.request({
    url: ApiUrl.LIST_SELL_COIN,
    method: "POST",
    data: payload,
    tokenRequired: true,
  });
};
