import * as S from "./style";
import PageTitle from "components/PageTitle";
import bgTitleImg from "assets/images/4.jpg";
import React from "react";
import { Tabs, TabsProps } from "antd";
import DataBuy from "./DataBuy";
import DataSell from "./DataSell";

const onChange = (key: string) => {
  console.log(key);
};

const items: TabsProps["items"] = [
  {
    key: "1",
    label: "코인구매 신청내역",
    children: <DataBuy />,
  },
  {
    key: "2",
    label: "코인 판매 신청내역",
    children: <DataSell />,
  },
];

function Mypage() {
  return (
    <React.Fragment>
      <PageTitle backgroundImage={bgTitleImg} title="마이페이지" />
      <S.Wrapper>
        <div className="container container-bs sub-page inquire">
          <div className="heading-text heading-section">
            <h2>마이페이지</h2>
          </div>
          <S.Content>
            <Tabs defaultActiveKey="1" items={items} onChange={onChange} />
          </S.Content>
        </div>
      </S.Wrapper>
    </React.Fragment>
  );
}

export default Mypage;
