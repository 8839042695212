import Footer from "components/Footer";
import Header from "components/Header";
import * as S from "./style";
import LoadSpinner from "components/SpinnerLoading";
import { useSelector } from "react-redux";
import { RootState } from "app/store";
import ModalList from "components/CommonModals";

interface Props {
  children: JSX.Element;
}

const DefaultLayout = ({ children }: Props) => {
  const isLoading = useSelector((state: RootState) => state.loading.isLoading);

  return (
    <S.Wrapper>
      <ModalList />
      <Header />
      {isLoading && <LoadSpinner isOverlay />}
      <S.Body>{children}</S.Body>
      {/* <Footer /> */}
    </S.Wrapper>
  );
};

export default DefaultLayout;
