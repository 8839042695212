import styled from "styled-components";

export const Wrapper = styled.div`
  .over-layout-menu {
    width: 100%;
    height: 100%;
    opacity: 0;
    visibility: hidden;
    background-color: rgba(0, 0, 0, 0.75);
    transition: all 0.4s ease;
    position: fixed;
    display: block;
    z-index: 11;
    left: 0;
    right: 0;
    &.active {
      opacity: 1;
      visibility: visible;
    }
  }
`;

export const BoxMenu = styled.div`
  @media (max-width: 1024px) {
    display: block;
    width: 100%;
    overflow: hidden;
    position: fixed;
    top: 0;
    right: 0;
    visibility: hidden;
    z-index: 111;
    width: 300px;
    height: 100%;
    transform: translateX(100%);
    transition: all 0.4s ease;
    background-color: #fff;
    box-shadow: 0 12px 6px rgba(0, 0, 0, 0.06);
    &.open-menu {
      visibility: visible;
      transform: translateX(0);
    }
    > .container {
      width: 100%;
      height: 100%;
      > nav {
        width: 100%;
        height: 100%;
        > ul {
          width: 100%;
          height: 100%;
        }
      }
    }
  }
`;

export const CloseMenu = styled.div`
  display: flex;
  justify-content: flex-end;
  > a {
    width: 40px;
    height: 40px;
  }
  .lines {
    &::before,
    &::after {
      background-color: #000000 !important;
      top: 5px !important;
    }
  }
`;
