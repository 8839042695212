import styled from "styled-components";

export const Wrapper = styled.section<{ data: string }>`
  background-color: #181818 !important;
  height: 436px;
  display: flex;
  align-items: center;
  &::before {
    content: '';
    background-image: url(${({ data }) => data});
    background-position: 0 0;
    opacity: 0.3;
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    z-index: 11;
    height: 436px;
  }
`;
