import Slider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import * as S from "./style";
import slideImg1 from "../../assets/images/slider-1.jpg";
import slideImg2 from "../../assets/images/slider-2.jpg";
import slideImg3 from "../../assets/images/slider-3.jpg";

function SliderComp(): JSX.Element {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    initialSlide: 1,
  };
  return (
    <S.Wrapper>
      <Slider {...settings}>
        {/* Slide 1 */}
        {/* <S.SlideInner className="slide" data={slideImg1}>
          <div className="bg-overlay overlay-slide" />
          <div className="container">
            <div className="slide-captions text-left text-light">
              <h1>세상에서 재테크가 제일 쉬웠어요</h1>
              <p>
                변동성이 너무나도 큰 디지털 자산 시장 24시간 쳐다만 보다 폐인이
                되는 것은 시간문제
                <br />
                제대로 된 정보 없는 개미들은, 적게 벌고, 많이 잃는 것이 90%
                이상이죠
                <br />
                <br />
                {SITE_NAME}의 전략투자 자동매매를 만나면, 당신을 폐인의 삶에서
                해방시켜 드립니다.
                <br />
                당신의 일상을 풍요롭게 만들어 드립니다.
              </p>
            </div>
          </div>
        </S.SlideInner> */}
        {/* end: Slide 1 */}
        {/* Slide 2 */}
        <S.SlideInner className="slide" data={slideImg2}>
          <div className="bg-overlay overlay-slide" />
          {/* <div className="container">
            <div className="slide-captions text-left text-light">
              <h1>요즘 세상에 12% 수익이 말이 돼?</h1>
              <p className="text-small">
                제 1금융, 2금융권에서는 말도 안되는 수익률이지만 변동성이
                너무나도 큰 디지털 자산 시장에서는 말이 됩니다.
                <br />
                너무나도 당연히요.
                <br />
                <br />
                {SITE_NAME}의 전략투자 자동매매의 리스크가 걱정된다면 예치보상
                프로그램으로 확정 이자를 누리세요.
                <br />
                당신의 자산을 리스크에서 해방시켜 드립니다. 당신의 일상을
                든든하게 지켜 드립니다.
              </p>
            </div>
          </div> */}
        </S.SlideInner>
        {/* end: Slide 2 */}
        {/* Slide 3 */}
        {/* <S.SlideInner className="slide" data={slideImg3}>
          <div className="bg-overlay overlay-slide"/>
          <div className="container">
            <div className="slide-captions text-left text-light">
              <h1>왜 이리 복잡해? 간편하게 원클릭!</h1>
              <p className="text-small">
                디지털 자산 시장의 접근성이 복잡하고 어려울 땐~! 보편화된
                전자상거래 간편결제처럼,
                <br />
                {SITE_NAME}의 간편구매, 간편판매를 활용해 보세요. 원클릭 한 번이면
                모든게 다 이루어집니다.
                <br />
                <br />
                {SITE_NAME}의 간편구매, 간편판매 시스템은,당신의 복잡한 일상을
                쉽게 비워드립니다.
                <br />
                당신의 시간을 소중하고 빠르게 지켜 드립니다.
              </p>
            </div>
          </div>
        </S.SlideInner> */}
        {/* end: Slide 3 */}
      </Slider>
    </S.Wrapper>
  );
}

export default SliderComp;
