import { SECOND_PER_MINUTE } from "constant/configs";


export const MoneyConvert = (value: any, toFixed?: boolean) => {
  if (value === undefined || value === null || value === "") return 0;

  if (toFixed) {
    const fixedValue = Number(value).toFixed(2);
    const [integerPart, decimalPart] = fixedValue.split(".");

    if (decimalPart === "00") {
      return integerPart.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
    }

    return fixedValue.replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  if (Number.isInteger(Number(value))) {
    return value.toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
  }

  return Number(value).toString().replace(/(\d)(?=(\d{3})+(?!\d))/g, "$1,");
};

export const stringwithhyphen = (array: any[] | undefined) => {
  return array?.map((element) => element?.name).join("-");
};

export const phoneNumberEncrypt = (phoneNumber: string) => {
  const newPhoneNumber =
    phoneNumber.substring(0, 3) +
    phoneNumber.substring(4, 8).replace(/[0-9]/g, "*") +
    phoneNumber.substring(9, phoneNumber.length);
  return newPhoneNumber;
};

export const formatNumberFeed = (value: number | string): string => {
  if (Number(value) >= 1000000) {
    return (Number(value) / 1000000).toFixed(0) + "M";
  } else if (Number(value) >= 10000) {
    return (Number(value) / 1000).toFixed(0) + "K";
  } else {
    return value.toString();
  }
};

export function formatNumberWithCommas(
  value: string,
  allowZero: boolean = false
): string {
  if (!value || (value === "0" && !allowZero)) {
    return "";
  }
  let numericValue = value.toString().replace(/[^0-9]/g, "");

  if (numericValue.length > 1 && numericValue[0] === "0" && !allowZero) {
    numericValue = numericValue.substring(1);
  }

  return numericValue.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
}

export function onNumericInputChange(value: any) {
  const reg = /^-?\d*(\.\d*)?$/;
  if ((!isNaN(value) && reg.test(value)) || value === "" || value === "-") {
    return value;
  }
  return false;
}

// export const amountExchange = (
//   rate_buy_sell: string | number,
//   rate_krw_usdt: string | number,
//   value: string | number
// ) => {
//   const result = (
//     Number(value) / Number(rate_krw_usdt) -
//     ((Number(value) / Number(rate_krw_usdt)) * Number(rate_buy_sell)) / 100
//   ).toFixed(2);
//   if (result === "NaN") {
//     return 0;
//   }
//   return result;
// };


export const amountExchange = (
  rate_buy_sell: string | number,
  rate_krw_usdt: string | number,
  value: string | number,
  type: "SELL" | "BUY"
): number => {
  // Convert inputs to numbers
  const rateBuySell = Number(rate_buy_sell);
  const rateKrwUsdt = Number(rate_krw_usdt);
  const inputValue = Number(value);

  // Check for invalid conversion
  if (isNaN(rateBuySell) || isNaN(rateKrwUsdt) || isNaN(inputValue) || rateKrwUsdt === 0) {
    return 0;
  }

  // Calculate base amount
  const baseAmount = inputValue / rateKrwUsdt;
  
  // Calculate result based on type
  const result = type === "BUY"
    ? baseAmount - (baseAmount * rateBuySell) / 100
    : baseAmount + (baseAmount * rateBuySell) / 100;

  // Return the result rounded to 2 decimal places
  return parseFloat(result.toFixed(2));
};

export const getStringTime = (time: number): string => {
  return time >= 10 || time <= -10 ? `${time}` : `0${time}`
}
export const secondToMinute = (num: number): string => {
  const minute = getStringTime(Math.floor(num / SECOND_PER_MINUTE))
  const second = getStringTime(num % SECOND_PER_MINUTE)

  return `${minute}:${second}`
}
export const secondToHours = (num: number, spearator: string = ":"): string => {
  const hours = getStringTime(Math.abs(Math.floor(num / 3600)))
  const minutes = getStringTime(Math.abs(Math.floor((num % 3600) / 60)))
  const seconds = getStringTime(Math.abs(Math.floor((num % 3600) % 60)))
  return `${hours}${spearator}${minutes}${spearator}${seconds}`
}
export function b64DecodeUnicode(str: string) {
  return decodeURIComponent(
    atob(str)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2)
      })
      .join(""),
  )
}