import MainLayout from "layouts/MainLayout";
import { Redirect, Route, Switch } from "react-router-dom";
// import router, { RouterType } from "router";
import { useAuth } from "customHook/useAuth";
import { RouterType, privateRouter, router } from "router";

function PrivateRoute({ component: TargetPage, ...rest }: any) {
  const { isLogined } = useAuth();
  return (
    <Route
      {...rest}
      render={(props) =>
        isLogined ? <TargetPage {...props} /> : <Redirect to={"/"} />
      }
    />
  );
}

function App() {
  return (
    <Switch>
      <MainLayout>
        <>
          {router.map((r: RouterType, i: number) => {
            return (
              <Route
                key={i}
                exact={r.exact}
                path={r.path}
                component={r.component}
              />
            );
          })}
          {privateRouter.map((r: RouterType, i: number) => {
            return (
              <PrivateRoute
                key={i}
                exact={r.exact}
                path={r.path}
                component={r.component}
              />
            );
          })}
        </>
      </MainLayout>
    </Switch>
  );
}

export default App;
