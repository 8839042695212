import useWindowSize from "hooks/useWindowSize";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { animateScroll as scroll } from "react-scroll";
import logoA from "../../assets/images/logo-a.png";
import logoB from "../../assets/images/logo-b.png";
import * as S from "./style";
import { useAuth } from "customHook/useAuth";
import { Button, message } from "antd";
import { logout } from "features/user/userSlice";
import { useAppDispatch } from "app/hooks";
import { openLoading, closeLoading } from "features/loading/loadingSlice";

// var scroller = Scroll.scroller;
// import { Link, useLoaderData } from "react-router-dom";

function Header() {
  const [scrollPosition, setScrollPosition] = useState<number>(0);
  const [isOpenMenu, setIsOpenMenu] = useState<boolean>(false);
  const { width }: any = useWindowSize();
  const { isLogined } = useAuth();
  const dispatch = useAppDispatch();
  const [messageApi, contextHolder] = message.useMessage();

  useEffect(() => {
    const handleScroll = () => {
      setScrollPosition(window.scrollY);
    };

    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleShowMenuMobile = () => {
    if (width <= 1024) {
      setIsOpenMenu(!isOpenMenu);
    }
  };

  const scrollToElement = (id: string) => {
    const element = document.getElementById(id);
    if (element) {
      const position = element.offsetTop;
      scroll.scrollTo(position, {
        spy: true,
        smooth: true,
        offset: -50,
        duration: 500,
      });
    }
  };

  const handleClickLogo = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
  };

  return (
    <S.Wrapper>
      {contextHolder}
      <header
        id="header"
        className={`submenu-light ${
          width > 1024 && scrollPosition > 100 ? "header-sticky" : "dark"
        } ${width > 1024 && scrollPosition > 200 ? "sticky-active" : "dark"}`}
      >
        <div className="header-inner">
          <div className="container">
            {/*Logo*/}
            <div id="logo" onClick={handleClickLogo}>
              <Link to="/">
                <span className="logo-default">
                  <img width="270px" alt="image" src={logoB} />
                </span>
                <span className="logo-dark">
                  <img width="270px" alt="image" src={logoA} />
                </span>
              </Link>
            </div>
            <div id="mainMenu-trigger">
              <a onClick={handleShowMenuMobile} className="lines-button x">
                <span className="lines" />
              </a>
            </div>
            {/*end: Navigation Resposnive Trigger*/}
            {/*Navigation*/}
            {width <= 1024 && (
              <div
                className={`over-layout-menu ${isOpenMenu ? "active" : ""}`}
                onClick={() => setIsOpenMenu(false)}
              />
            )}
            <S.BoxMenu id="mainMenu" className={isOpenMenu ? "open-menu" : ""}>
              <div className="container">
                {width <= 1024 && (
                  <S.CloseMenu>
                    <a
                      onClick={() => setIsOpenMenu(false)}
                      className="lines-button x toggle-active"
                    >
                      <span className="lines" />
                    </a>
                  </S.CloseMenu>
                )}

                <nav>
                  <ul>
                    <li>
                      <Link to="/terms-of-use">이용약관 </Link>
                    </li>
                    <li>
                      <Link to="/privacy-policy">개인정보 처리방침</Link>
                    </li>
                    {/* <li>
                      <Link
                        to="/#menu1"
                        onClick={() => scrollToElement("menu1")}
                      >
                        전략투자
                      </Link>
                    </li> */}
                    {/* <li>
                      <Link
                        to="/#menu2"
                        onClick={() => scrollToElement("menu2")}
                      >
                        예치보상
                      </Link>
                    </li> */}
                    {isLogined && (
                      <>
                        <li>
                          <Link to="/purchase">간편구매</Link>
                        </li>
                        <li>
                          <Link to="/sell">간편판매</Link>
                        </li>
                        <li>
                          <Link to="/mypage">마이페이지</Link>
                        </li>
                      </>
                    )}
                    {/* <li>
                      <Link to="/identity">본인인증</Link>
                    </li> */}
                    <li>
                      <Link to="/inquire">고객지원</Link>
                    </li>
                    {isLogined ? (
                      <li>
                        <Button
                          className="btn btn-danger btn-rounded"
                          style={{ color: "#ffffff", height: 50 }}
                          onClick={() => {
                            dispatch(logout());
                            dispatch(openLoading());
                            messageApi.open({
                              type: "success",
                              content: "로그아웃 성공했습니다!",
                              duration: 2,
                              onClose: () => {
                                dispatch(closeLoading());
                              },
                            });
                          }}
                        >
                          로그아웃
                        </Button>
                      </li>
                    ) : (
                      <>
                        <li>
                          <Link
                            to="/login"
                            className="btn btn-v01 btn-rounded"
                            style={{ color: "#ffffff" }}
                          >
                            로그인
                          </Link>
                        </li>
                        <li>
                          <Link
                            to="/register"
                            className="btn btn-v02 btn-rounded"
                            style={{ color: "#ffffff" }}
                          >
                            회원가입
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </nav>
              </div>
            </S.BoxMenu>
            {/*end: Navigation*/}
          </div>
        </div>
      </header>
    </S.Wrapper>
  );
}

export default Header;
