import { store } from "app/store";
import axios, { AxiosInstance, AxiosRequestConfig } from "axios";
import { API_URL } from "constant/configs";
import { logout } from "features/user/userSlice";
import { createBrowserHistory } from "history";

const api: any = axios.create({
  baseURL: API_URL,
  timeout: 5000,
  headers: {
    "Content-Type": "application/json",
  },
});

export default api;

interface AxiosRequestConfigCustom extends AxiosRequestConfig {
  tokenRequired?: boolean;
}

class API {
  api: AxiosInstance;
  history: any;

  constructor() {
    this.api = axios.create({
      baseURL: API_URL,
      headers: {
        "Content-Type": "application/json",
      },
    });
    this.history = createBrowserHistory();
  }

  private setAuthToken(): void {
    const token = store.getState().user.currentUser?.accessToken;
    this.api.defaults.headers.common["Authorization"] = `Bearer ${token}`;
    // console.log("token", token);
  }

  public async request<T>(config: AxiosRequestConfigCustom): Promise<T> {
    if (config.tokenRequired) {
      this.setAuthToken();
    }
    return new Promise((resolve, reject) => {
      this.api
        .request(config)
        .then((res) => resolve(res.data))
        .catch((err) => {
          if (err.response) {
            // console.log("err.response", err.response)
            if (err.response.status === 403) {
              store.dispatch(logout());
            }
            resolve({ data: [], error: err.response.data } as T);
          }
          reject(err);
        });
    });
  }
}

export const apiConfig = new API();
