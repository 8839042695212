import styled from "styled-components";

export const Wrapper = styled.section``;

export const Content = styled.div`
  max-width: 1000px;
  margin-right: auto;
`;

export const ModalTitle = styled.p`
  text-align: center;
  padding-top: 20px;
`;
